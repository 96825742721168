import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 221" fill="none" {...props}>
    <g filter="url(#a)">
      <path
        fill="#E6E8FA"
        stroke="#707070"
        strokeWidth={8}
        d="m17.252 66.094-2.925 2.765 2.784 2.907 124 129.5 2.889 3.018 2.889-3.018 124-129.5 2.784-2.907-2.925-2.765-58.693-55.5-1.156-1.094H77.102l-1.157 1.094-58.693 55.5Z"
      />
      <path
        fill="#707070"
        d="M109.04 136.5v-15.616l31.232-25.728c6.229-5.205 10.496-9.387 12.8-12.544 2.389-3.157 3.584-6.613 3.584-10.368 0-4.01-1.195-7.125-3.584-9.344-2.389-2.219-5.547-3.328-9.472-3.328-3.584 0-6.827 1.024-9.728 3.072-2.816 1.963-6.059 5.205-9.728 9.728L110.32 61.364c4.864-6.741 9.856-11.648 14.976-14.72 5.12-3.072 11.648-4.608 19.584-4.608 9.557 0 17.237 2.603 23.04 7.808 5.888 5.205 8.832 12.117 8.832 20.736v.256c0 3.755-.469 7.168-1.408 10.24-.853 2.987-2.432 5.93-4.736 8.832a74.287 74.287 0 0 1-7.424 8.064c-2.645 2.389-6.272 5.376-10.88 8.96l-16 12.544h41.344V136.5H109.04Z"
      />
    </g>
    <defs>
      <filter
        id="a"
        width={286.691}
        height={220.566}
        x={0.654}
        y={0.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={4} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_264_2065" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_264_2065" result="shape" />
      </filter>
    </defs>
  </svg>
);
export { SvgComponent as SecondPlaceIcon };

import { StoresMap } from "@/components/stores/StoresMap";
import { useUser } from "@/hooks/useUser";
import Map from "@mui/icons-material/MapTwoTone";
import { Box, darken, Stack } from "@mui/material";
import { limit, query, QueryConstraint, where } from "firebase/firestore";
import { useMemo, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import { getLeaderboardStoreMetadataCollection } from "../../firebase/collections";
import {
  leaderboardStoreMetadataConverter,
  StoreMetadata,
} from "../../firebase/converters/leaderboardStoreMetadata";

const StoreLocatorRoute = () => {
  const [selectedStore, setSelectedStore] = useState<StoreMetadata | null>(null);
  const [{ user }] = useUser();

  const storesWithValidAddresses = useMemo(() => {
    const filters: QueryConstraint[] = [];
    filters.push(where("addressValidity.result.geocode", "!=", null));
    filters.push(limit(200));
    return query(getLeaderboardStoreMetadataCollection(), ...filters).withConverter(
      leaderboardStoreMetadataConverter,
    );
  }, []);

  const [stores] = useCollectionData(storesWithValidAddresses);

  const visibleStores = useMemo(() => {
    return stores?.filter((store) => !store.hideMapLocation);
  }, [stores]);

  return (
    // <ErrorBoundary eventName="store_map_error">
    <Stack>
      <Stack
        gap={4}
        direction="row"
        width="100%"
        height={{
          tablet: user ? "calc(90dvh - 48px)" : "calc(90dvh - 132px)",
          mobile: user ? "calc(100dvh - 48px)" : "calc(100dvh - 128px)",
        }}
        position="relative"
        justifyContent="center"
      >
        <StoresMap
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
          visibleStores={visibleStores}
        />
      </Stack>
      <Box
        sx={{
          my: 4,
          display: "grid",
          gridAutoFlow: "column",
          width: "fit-content",
          alignItems: "center",
          px: 2,
          gap: 4,
        }}
      >
        <Map fontSize="large" sx={{ width: 48, height: 48, color: "primary.main" }} />

        <Stack gap={0.5}>
          <Stack
            direction="row"
            sx={{
              fontSize: "28px",
              fontWeight: 500,
              letterSpacing: "-0.02em",
              color: "secondary.main",
            }}
            alignItems="center"
          >
            Get on the Map
          </Stack>
          <Box sx={{ fontSize: "16px", fontWeight: 300, color: "grey.600" }}>
            Add Your Store to Our TechDisc Locator
          </Box>
        </Stack>

        <Box
          component={Link}
          to="mailto:help@techdisc.com?subject=Store%20Map%20Request"
          sx={{
            px: 2,
            py: 1.5,
            color: "white",
            textDecoration: "none",
            "&:hover": {
              color: "white",
              textDecoration: "none",
              backgroundColor: (theme) => darken(theme.palette.primary.main, 0.2),
            },
            backgroundColor: "primary.main",
            borderRadius: 1,
            width: "fit-content",
          }}
        >
          Contact Us
        </Box>
      </Box>
    </Stack>
    // </ErrorBoundary>
  );
};

export default StoreLocatorRoute;

import { itemVariants } from "@/components/routes/variants";
import type { PlayEvent } from "@/types/events";
import { Box, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function PlayEvents({ playEvents }: { playEvents?: PlayEvent[] }) {
  const navigate = useNavigate();
  return playEvents
    ?.filter((event) => event.isLive)
    .map((event) => (
      <Box
        component={motion.div}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 1 }}
        variants={itemVariants}
        key={event.id}
        sx={{
          borderRadius: 4,
          maxWidth: "512px",
          width: "100%",
          background: `url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%23cd2032' fill-opacity='0.25' fill-rule='evenodd'/%3E%3C/svg%3E") ${event.branding?.backgroundColor ?? "white"}`,
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
        }}
      >
        <Stack
          component={motion.button}
          onClick={() => navigate(`/play/leaderboards/event/${event.id}`)}
          sx={{
            aspectRatio: 1,
            width: "100%",
            userSelect: "none",
            textAlign: "center",
            justifyContent: "space-between",
            alignItems: "center",
            color: event.branding?.primaryColor ?? "primary.main",
            background: `url("${event.assets?.playImageUrl}") `,
            backgroundSize: "60%",
            backgroundRepeat: "no-repeat",
            backgroundOrigin: "content-box",
            backgroundPosition: { mobile: "bottom", laptop: "center" },
            p: 4,
            fontWeight: 600,
            borderRadius: 4,
            letterSpacing: "-0.1rem",
            lineHeight: 1.1,
          }}
        >
          <Box
            sx={{
              fontWeight: 600,
              filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.8))",
              fontSize: { laptop: "2.6rem", tablet: "1.8rem", mobile: "1.5rem" },
            }}
          >
            {event.eventName}
          </Box>
        </Stack>
      </Box>
    ));
}

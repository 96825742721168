import { itemVariants } from "@/components/routes/variants";
import type { StrokePlayLeaderboardEntry } from "@/model/leaderboard";
import type { SimulatorLayout } from "@/types/simulator";
import { Box, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function PlayLayouts({
  layouts,
}: {
  layouts?: (SimulatorLayout & { leaderboard?: StrokePlayLeaderboardEntry[] })[];
}) {
  const navigate = useNavigate();

  return layouts?.map((layout) => (
    <Box
      key={layout?.id}
      component={motion.div}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 1 }}
      variants={itemVariants}
      sx={{
        width: "100%",
        maxWidth: "512px",
        borderRadius: 4,
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' width='80' height='80'%3E%3Cpath fill='%23bbdbf9' fill-opacity='0.2' d='M14 16H9v-2h5V9.87a4 4 0 1 1 2 0V14h5v2h-5v15.95A10 10 0 0 0 23.66 27l-3.46-2 8.2-2.2-2.9 5a12 12 0 0 1-21 0l-2.89-5 8.2 2.2-3.47 2A10 10 0 0 0 14 31.95V16zm40 40h-5v-2h5v-4.13a4 4 0 1 1 2 0V54h5v2h-5v15.95A10 10 0 0 0 63.66 67l-3.47-2 8.2-2.2-2.88 5a12 12 0 0 1-21.02 0l-2.88-5 8.2 2.2-3.47 2A10 10 0 0 0 54 71.95V56zm-39 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm40-40a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM15 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm40 40a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'%3E%3C/path%3E%3C/svg%3E")`,
        boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.5)",
        backgroundColor: "#1c2833",
      }}
    >
      <Stack
        component={motion.button}
        onClick={() => navigate(`/play/leaderboards/${layout?.id}`)}
        sx={{
          aspectRatio: 1,
          width: "100%",
          userSelect: "none",
          textAlign: "center",
          justifyContent: "space-between",
          alignItems: "center",
          color: "primary.light",
          background: `url("${layout?.assets?.playImageUrl}") `,
          backgroundSize: "90%",
          backgroundRepeat: "no-repeat",
          backgroundOrigin: "content-box",
          backgroundPosition: { mobile: "bottom", laptop: "center" },
          p: 4,
          fontWeight: 600,
          borderRadius: 4,
          letterSpacing: "-0.1rem",
          lineHeight: 1.1,
        }}
      >
        <Box
          component={motion.span}
          layout="position"
          layoutId={`layout-name-${layout?.id}`}
          sx={{
            fontWeight: 600,
            filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.8))",
            fontSize: { laptop: "2.6rem", tablet: "1.8rem", mobile: "1.5rem" },
          }}
        >
          {layout?.name}
        </Box>
      </Stack>
    </Box>
  ));
}

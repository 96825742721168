import { firebaseApp } from "@/firebaseConfig";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckCircleTwoTone, ContentCopy } from "@mui/icons-material";
import { Box, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import copy from "copy-to-clipboard";
import { getFunctions, httpsCallable } from "firebase/functions";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Title from "../../dashboard/Title";
import useNotify from "../../hooks/useNotify";
import { useUser } from "../../hooks/useUser";
import { useUserMetadata } from "../../hooks/useUserMetadata";
import { UserProfile, userProfileSchema } from "../../model/UserProfile";
import { SettingsPage } from "../../settings/SettingsPage";
import { storeLeaderboardUserMetadata, storeUserSettings } from "../../summaryUtils";
import { useGlobal } from "../providers/GlobalProvider";
import UserProfileSettings from "./UserProfileSettings";
import { uploadUserProfilePhoto } from "./utils";

export function UserSettings() {
  const { userSettings, featureFlags, userRoles } = useGlobal();
  const [{ trueUserId }] = useUser();
  const notify = useNotify();
  const [userMetadata] = useUserMetadata(trueUserId);
  const [profilePhoto, setProfilePhoto] = React.useState<File | null | undefined>(undefined);

  const methods = useForm<UserProfile>({
    resolver: yupResolver(userProfileSchema),
    defaultValues: userMetadata
      ? {
          ...userMetadata,
          preferredMeasurementSystem: userSettings?.preferredMeasurementSystem ?? "us",
        }
      : {
          name: "",
          photo: "",
          pdgaNumber: "",
          preferredMeasurementSystem: "us",
        },
  });

  const handleDeletePhoto = () => {
    methods.setValue("photo", "", { shouldDirty: true });
    setProfilePhoto(null);
  };

  const handleSelectPhoto = (photo: File) => {
    methods.setValue("photo", URL.createObjectURL(photo), { shouldDirty: true });
    setProfilePhoto(photo);
  };

  const handleReset = React.useCallback(() => {
    if (userMetadata && userSettings) {
      methods.reset({
        ...userMetadata,
        preferredMeasurementSystem: userSettings.preferredMeasurementSystem,
      });
    }
  }, [userMetadata, userSettings, methods]);

  React.useEffect(() => {
    handleReset();
  }, [handleReset]);

  const handleSave = React.useCallback(
    async (profileFields: UserProfile) => {
      if (trueUserId) {
        const { preferredMeasurementSystem, ...userMetadata } = profileFields;
        try {
          // If there is a profile photo, upload it and get the url
          const photo = profilePhoto ? await uploadUserProfilePhoto(profilePhoto) : undefined;
          // Create the user profile
          if (photo) {
            userMetadata.photo = photo[200];
          }
          // Save the user profile
          await storeLeaderboardUserMetadata(trueUserId, userMetadata);
          await storeUserSettings(trueUserId, { preferredMeasurementSystem });
          notify("success", "Settings saved successfully.");

          // Reset the form to the new values
          methods.reset({}, { keepValues: true });
        } catch (e) {
          notify("error", "There was a problem updating your settings, please try again.");
        }
      }
    },
    [trueUserId, profilePhoto, methods, notify],
  );
  const settingsTabs = [
    {
      label: "Profile",
      content: (
        <UserProfileSettings onSelectPhoto={handleSelectPhoto} onDeletePhoto={handleDeletePhoto} />
      ),
    },
  ];

  if (featureFlags.metric_enabled) {
    settingsTabs.push({
      label: "Preferences",
      content: <UserPreferences methods={methods} />,
    });
  }

  // check for DEVELOPER role
  if (userRoles.indexOf("DEVELOPER") > -1) {
    settingsTabs.push({
      label: "Developer",
      content: <DeveloperPreferences />,
    });
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSave)}>
        <SettingsPage id="user-settings" title="Profile" tabs={settingsTabs} methods={methods} />
      </form>
    </FormProvider>
  );
}
function UserPreferences(props: {
  methods: ReturnType<typeof useForm<UserProfile>>;
}): React.ReactNode {
  const { methods } = props;
  return (
    <Stack gap={3}>
      <Controller
        name="preferredMeasurementSystem"
        control={methods.control}
        render={({ field }) => (
          <Stack gap={0.5}>
            <Title variant="secondary">Measurement System</Title>
            <RadioGroup {...field} sx={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel value="us" control={<Radio />} label="US (Imperial)" />
              <FormControlLabel value="metric" control={<Radio />} label="Metric" />
            </RadioGroup>
          </Stack>
        )}
      />
    </Stack>
  );
}
function DeveloperPreferences() {
  const notify = useNotify();
  const [token, setToken] = React.useState<string | null>(null);
  const [copied, setCopied] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getToken = async () => {
      const result = await httpsCallable(
        getFunctions(firebaseApp),
        "createPlaySessionToken",
      )({
        loadLastThrow: true,
      });
      setToken(result?.data?.jwt);
    };
    getToken();
  }, []);

  return (
    <Stack gap={2} sx={{ mt: "auto" }}>
      <Title variant="secondary">Game API Token</Title>
      <Stack direction="row" gap={1}>
        <AnimatePresence>
          {token && (
            <Box
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              component={motion.button}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (token) {
                  copy(token);
                  notify("success", "Token copied to clipboard.");
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 2000);
                }
              }}
              sx={{
                flexGrow: 1,
                backgroundColor: "grey.50",
                transition: "all 0.2s ease-in-out",
                p: 2,
                pr: 4,
                color: copied ? "primary.dark" : "grey.700",
                borderRadius: 1,
                border: "1px solid",
                borderColor: "grey.300",
                fontSize: "1rem",
                position: "relative",
                fontFamily: "monospace",
                maxWidth: (theme) => theme.breakpoints.values.md,
                minHeight: "48px",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                "&:hover": {
                  "& > svg": {
                    opacity: 1,
                  },
                },
              }}
            >
              {token}
              {copied ? (
                <CheckCircleTwoTone
                  fontSize="small"
                  color="primary"
                  sx={{
                    position: "absolute",
                    right: 12,
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: 1,
                  }}
                />
              ) : (
                <ContentCopy
                  fontSize="small"
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: "50%",
                    transform: "translateY(-50%)",
                    opacity: 0,
                    color: "grey.700",
                  }}
                />
              )}
            </Box>
          )}
        </AnimatePresence>
      </Stack>
    </Stack>
  );
}

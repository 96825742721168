import { Variants } from "framer-motion";

export const containerVariants: Variants = {
  visible: {
    transition: {
      staggerChildren: 0.2, // Reduced stagger time for quicker animations
      // ease: "anticipate"
    },
  },
};

export const itemVariants: Variants = {
  hidden: { y: 10, transition: { duration: 0.2, ease: "easeInOut" } }, // Reduced movement for quicker rendering
  visible: {
    y: 0,
    transition: {
      duration: 0.2, // Reduced duration for quicker animations
      ease: "easeInOut",
    },
  },
};

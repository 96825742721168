import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 221" fill="none" {...props}>
    <g filter="url(#a)">
      <path
        fill="gold"
        stroke="#B8860B"
        strokeWidth={8}
        d="m17.17 65.594-2.925 2.765 2.784 2.907 124 129.5 2.889 3.018 2.889-3.018 124-129.5 2.784-2.907-2.925-2.765-58.693-55.5L210.817 9H77.019l-1.156 1.094-58.693 55.5Z"
      />
      <path
        fill="#B8860B"
        d="M134.61 136V62.144l-16.384 4.096-4.096-16.128 26.496-7.936h13.44V136H134.61Z"
      />
    </g>
    <defs>
      <filter
        id="a"
        width={286.691}
        height={220.566}
        x={0.572}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={4} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_264_2064" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_264_2064" result="shape" />
      </filter>
    </defs>
  </svg>
);
export { SvgComponent as FirstPlaceIcon };

import { DocumentData, Timestamp } from "firebase/firestore";
import { FlightNumbers } from "./throwSummary";

export enum DiscClass {
  Putter = "putter",
  PuttApproach = "putt-approach",
  Midrange = "midrange",
  Fairway = "fairway",
  Control = "control",
  Distance = "distance",
}

export interface Identity {
  id: string;
}

export enum FlightPlate {
  Puddle = "puddle",
  Flat = "flat",
  Neutral = "neutral",
  Dome = "dome",
}

export enum DiscWear {
  New = "new",
  FieldTested = "field",
  Seasoned = "seasoned",
  Worn = "worn",
}

export enum DiscAppearance {
  Solid = "solid",
  Overmold = "overmold",
  Burst = "burst",
}

export interface DiscPreset extends Identity {
  name: string;
  mold?: string;
  plastic?: string;
  manufacturer?: string;
  wear?: DiscWear;
  class?: DiscClass;
  colors?: {
    primary?: string;
    secondary?: string;
  };
  appearance?: DiscAppearance;
  flightPlate?: FlightPlate;
  flightNumbers: FlightNumbers;
  createTime?: Timestamp;
  updateTime?: Timestamp;
}

export interface DiscPresetDocumentData extends DocumentData {
  name: string;
  mold?: string;
  plastic?: string;
  manufacturer?: string;
  wear?: DiscWear;
  class?: DiscClass;
  colors?: {
    primary?: string;
    secondary?: string;
  };
  appearance?: DiscAppearance;
  flightPlate?: FlightPlate;
  flightNumbers: FlightNumbers;
  createTime?: Timestamp;
  updateTime?: Timestamp;
}

import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 221" fill="none" {...props}>
    <g filter="url(#a)">
      <path
        fill="#CD7F32"
        stroke="#8B4513"
        strokeWidth={8}
        d="m17.252 66.094-2.925 2.765 2.784 2.907 124 129.5 2.889 3.018 2.889-3.018 124-129.5 2.784-2.907-2.925-2.765-58.693-55.5-1.156-1.094H77.102l-1.157 1.094-58.693 55.5Z"
      />
      <path
        fill="#8B4513"
        d="M143.856 138.036c-15.019 0-27.051-5.376-36.096-16.128l13.568-12.8c6.656 7.595 14.251 11.392 22.784 11.392 4.096 0 7.424-1.067 9.984-3.2 2.56-2.219 3.84-5.12 3.84-8.704v-.256c0-3.925-1.664-6.955-4.992-9.088-3.328-2.219-7.936-3.328-13.824-3.328h-8.32l-3.2-12.416 22.912-23.296h-37.376V43.316h62.72v14.848L151.792 81.46c7.424 1.195 13.525 3.883 18.304 8.064 4.779 4.096 7.168 10.112 7.168 18.048v.256c0 8.875-3.029 16.128-9.088 21.76-6.059 5.632-14.165 8.448-24.32 8.448Z"
      />
    </g>
    <defs>
      <filter
        id="a"
        width={286.691}
        height={220.566}
        x={0.654}
        y={0.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={4} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_264_2069" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_264_2069" result="shape" />
      </filter>
    </defs>
  </svg>
);
export { SvgComponent as ThirdPlaceIcon };

import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

export type AvatarProps = {
  displayName?: string;
} & MuiAvatarProps;

export default function Avatar(props: AvatarProps) {
  const { displayName, ...muiAvatarProps } = props;
  const avatarProps = { alt: displayName };
  const overlayStyles = {
    opacity: muiAvatarProps.src || displayName ? 1 : 0,
    border: (theme) =>
      `1px solid ${muiAvatarProps.src ? theme.palette.grey[700] : theme.palette.grey[500]}`,
  };

  return (
    <>
      <Stack direction="row" gap={2} alignItems={"center"} textOverflow={"ellipsis"}>
        <MuiAvatar {...muiAvatarProps} {...avatarProps} sx={overlayStyles} />
        {displayName && (
          <Tooltip title={displayName} placement="top" arrow>
            <Typography
              variant="caption"
              noWrap
              fontSize="14px"
              sx={{ userSelect: "none", maxWidth: { mobile: "64px", tablet: "none" } }}
            >
              {displayName}
            </Typography>
          </Tooltip>
        )}
      </Stack>
    </>
  );
}

import { useGlobal } from "@/components/providers/GlobalProvider";
import { useUser } from "@/hooks/useUser";
import {
  createCheckoutSession,
  createPortalLink,
  hasPremiumFeature,
  PLAY_PRICE_MO_ID,
  PLAY_PRICE_YR_ID,
  PremiumFeature,
  PRO_PRICE_MO_ID,
  PRO_PRICE_YR_ID,
  STORE_PRICE_MO_ID,
  STORE_PRICE_YR_ID,
  useEntitlements,
  useStripeClaim,
} from "@/stripe";
import { Add, ChevronRight } from "@mui/icons-material";
import { Box, CircularProgress, Container, Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

export const PricingTable = () => {
  const { isStore, userRolesLoading } = useGlobal();
  return userRolesLoading ? (
    <CircularProgress />
  ) : isStore ? (
    <StorePricingTable />
  ) : (
    <UserPricingTable />
  );
};

const UserPricingTable = () => {
  const [visibleRates, setVisibleRates] = useState<"monthly" | "yearly">("monthly");
  const plans = [
    {
      name: "Core",
      price: {
        monthly: 0,
        yearly: 0,
      },
      stripeIds: {
        monthly: "",
        yearly: "",
      },
      features: [
        <Stack direction="row" gap={1} alignItems="flex-start" key="measure-all-metrics">
          <ChevronRight fontSize="small" />
          <Box>Measure all metrics with every throw</Box>
        </Stack>,
        <Stack direction="row" gap={1} alignItems="flex-start" key="2d-3d-simulated-flight-path">
          <ChevronRight fontSize="small" />
          <Box>2D & 3D simulated flight path</Box>
        </Stack>,
        <Stack direction="row" gap={1} alignItems="flex-start" key="virtual-driving-range">
          <ChevronRight fontSize="small" />
          <Box>
            Virtual driving range & one playable hole available, rotating throughout the season
          </Box>
        </Stack>,
        <Stack direction="row" gap={1} alignItems="flex-start" key="unlimited-throw-storage">
          <ChevronRight fontSize="small" />
          <Box>Unlimited Throw Storage & Throw Sets</Box>
        </Stack>,
      ],
    },
    {
      name: "Play",
      price: {
        monthly: 4.99,
        yearly: 49.99,
      },
      stripeIds: {
        monthly: PLAY_PRICE_MO_ID,
        yearly: PLAY_PRICE_YR_ID,
      },
      features: [
        <Stack
          direction="row"
          gap={0.5}
          alignItems="center"
          key="all-core-play-features"
          sx={{ color: "primary.main" }}
        >
          <Add />
          <strong>All Core features</strong>
        </Stack>,
        <Stack direction="row" gap={0.5} alignItems="flex-start" key="play-20-playable-holes">
          <ChevronRight fontSize="small" />
          <Box>Play 20+ playable holes in the 3D simulator</Box>
        </Stack>,
        <Stack direction="row" gap={0.5} alignItems="flex-start" key="play-all-stroke-play-courses">
          <ChevronRight fontSize="small" />
          <Box>
            Play all stroke-play courses <Box sx={{ fontWeight: 300 }}>(coming in 2025)</Box>
          </Box>
        </Stack>,
      ],
      entitlement: PremiumFeature.PLAY,
    },
    {
      name: "Premium",
      price: {
        monthly: 9.99,
        yearly: 99.99,
      },
      stripeIds: {
        monthly: PRO_PRICE_MO_ID,
        yearly: PRO_PRICE_YR_ID,
      },
      features: [
        <Stack
          direction="row"
          gap={0.5}
          alignItems="center"
          key="all-core-play-features"
          sx={{ color: "primary.main" }}
        >
          <Add />
          <strong>All Core & Play features</strong>
        </Stack>,
        <Stack direction="row" gap={0.5} alignItems="flex-start" key="automatic-video-capture">
          <ChevronRight fontSize="small" />
          <Stack>
            <Box>Automatic Video Capture</Box>
            <Box sx={{ fontWeight: 300 }}> (iOS-only, Android & Web coming in late 2024)</Box>
          </Stack>
        </Stack>,
      ],
      entitlement: PremiumFeature.PRO,
    },
  ];
  return (
    <Container sx={{ my: 4, mt: 8 }}>
      <Stack gap={6}>
        <Stack gap={3} justifyContent="center" alignItems="center">
          <Box
            sx={{
              letterSpacing: "-0.2rem",
              fontSize: "3rem",
              fontWeight: 200,
              width: { laptop: "500px" },
              textAlign: "center",
              lineHeight: 1.2,
            }}
          >
            Plans that <span style={{ fontWeight: 400 }}>grow</span> with{" "}
            <span style={{ fontWeight: 400 }}>your game</span>.
          </Box>
          <RateToggle selectedVisibleRates={visibleRates} onVisibleRatesChange={setVisibleRates} />
        </Stack>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              mobile: "1fr",
              tablet: "repeat(2, 1fr)",
              laptop: "repeat(3, 1fr)",
            },
            gap: 2,
          }}
        >
          {plans.map((plan, index) => (
            <Box
              key={`plan-${index}`}
              sx={{
                gridRow: {
                  mobile: "auto",
                  tablet: index === 0 ? "span 2" : "auto",
                  laptop: "auto",
                },
              }}
            >
              <Box
                sx={{
                  px: 3,
                  py: 3.5,
                  backgroundColor: "white",
                  borderRadius: 2,
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.1)",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Stack gap={2}>
                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      gap={1}
                    >
                      <TierName>{plan.name}</TierName>
                      {visibleRates === "yearly" && plan.price.yearly > 0 && (
                        <Box
                          initial={{ scale: 0.25 }}
                          animate={{ scale: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          component={motion.div}
                          sx={{
                            py: 0.5,
                            px: 1,
                            borderRadius: 4,
                            border: "2px solid",
                            borderColor: "primary.main",
                            color: "primary.main",
                            fontWeight: 600,
                            fontSize: "1.2rem",
                            letterSpacing: "-0.02rem",
                          }}
                        >
                          <Box sx={{ lineHeight: 1.1, fontSize: "1rem" }}>
                            {`Save ${Math.round(
                              ((plan.price.monthly * 12 - plan.price.yearly) / plan.price.yearly) *
                                100,
                            )}%`}
                          </Box>
                        </Box>
                      )}
                    </Stack>
                    <TierPrice price={plan.price} visibleRates={visibleRates} />
                  </Stack>
                  <TierFeatures features={plan.features} />
                </Stack>
                {plan.price.monthly > 0 && plan.price.yearly > 0 && (
                  <TierPurchaseButton
                    entitlement={plan.entitlement}
                    stripePrices={plan.stripeIds}
                    rate={visibleRates}
                  />
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Stack>
    </Container>
  );
};

const StorePricingTable = () => {
  const [visibleRates, setVisibleRates] = useState<"monthly" | "yearly">("monthly");
  const plans = [
    {
      name: "Core",
      price: {
        monthly: 0,
        yearly: 0,
      },
      stripeIds: {
        monthly: "",
        yearly: "",
      },
      features: [
        <Stack direction="row" gap={1} alignItems="flex-start" key="measure-all-metrics">
          <ChevronRight fontSize="small" />
          <Box>Test Drive with Throw Metrics</Box>
        </Stack>,
        <Stack direction="row" gap={1} alignItems="flex-start" key="measure-all-metrics">
          <ChevronRight fontSize="small" />
          <Box>Standard Pin on our Stores Map</Box>
        </Stack>,
        <Stack direction="row" gap={1} alignItems="flex-start" key="measure-all-metrics">
          <ChevronRight fontSize="small" />
          <Box>Measure all metrics with every throw</Box>
        </Stack>,
        <Stack direction="row" gap={1} alignItems="flex-start" key="2d-3d-simulated-flight-path">
          <ChevronRight fontSize="small" />
          <Box>2D & 3D simulated flight path</Box>
        </Stack>,
        <Stack direction="row" gap={1} alignItems="flex-start" key="virtual-driving-range">
          <ChevronRight fontSize="small" />
          <Box>
            Virtual driving range & one playable hole available, rotating throughout the season
          </Box>
        </Stack>,
        <Stack direction="row" gap={1} alignItems="flex-start" key="unlimited-throw-storage">
          <ChevronRight fontSize="small" />
          <Box>Unlimited Throw Storage & Throw Sets</Box>
        </Stack>,
      ],
    },
    {
      name: "Premium",
      price: {
        monthly: 99.99,
        yearly: 999.99,
      },
      stripeIds: {
        monthly: STORE_PRICE_MO_ID,
        yearly: STORE_PRICE_YR_ID,
      },
      features: [
        <Stack
          direction="row"
          gap={0.5}
          alignItems="center"
          key="all-core-features"
          sx={{ color: "primary.main" }}
        >
          <Add />
          <strong>All Core features</strong>
        </Stack>,
        <Stack direction="row" gap={0.5} alignItems="flex-start" key="play-20-playable-holes">
          <ChevronRight fontSize="small" />
          <Box>Monetize and sell throws using your TechDisc</Box>
        </Stack>,
        <Stack direction="row" gap={0.5} alignItems="flex-start" key="automatic-video-capture">
          <ChevronRight fontSize="small" />
          <Stack>
            <Box>Unlimited Automatic Video Capture</Box>
            <Box sx={{ fontWeight: 300 }}> (iOS-only, Android & Web coming in late 2024)</Box>
          </Stack>
        </Stack>,
        <Stack direction="row" gap={0.5} alignItems="flex-start" key="play-20-playable-holes">
          <ChevronRight fontSize="small" />
          <Box>Play all holes in the 3D simulator</Box>
        </Stack>,
        <Stack direction="row" gap={0.5} alignItems="flex-start" key="play-all-stroke-play-courses">
          <ChevronRight fontSize="small" />
          <Box>
            Play all stroke-play courses <Box sx={{ fontWeight: 300 }}>(coming in 2025)</Box>
          </Box>
        </Stack>,
        <Stack direction="row" gap={0.5} alignItems="flex-start" key="play-20-playable-holes">
          <ChevronRight fontSize="small" />
          <Box>Leaderboard for Speed, Spin, and Distance</Box>
        </Stack>,
        <Stack direction="row" gap={0.5} alignItems="flex-start" key="play-20-playable-holes">
          <ChevronRight fontSize="small" />
          <Stack>
            <Box>Quick Guest Login</Box>
            <Box sx={{ fontWeight: 300 }}> (Coming 2025)</Box>
          </Stack>
        </Stack>,
      ],
      entitlement: PremiumFeature.STORE,
    },
  ];
  return (
    <Container sx={{ my: 4, mt: 8 }}>
      <Stack gap={6}>
        <Stack gap={3} justifyContent="center" alignItems="center">
          <Box
            sx={{
              letterSpacing: "-0.2rem",
              fontSize: "3rem",
              fontWeight: 200,
              width: { laptop: "500px" },
              textAlign: "center",
              lineHeight: 1.2,
            }}
          >
            Plans that <span style={{ fontWeight: 400 }}>grow</span> with{" "}
            <span style={{ fontWeight: 400 }}>your store</span>.
          </Box>
          <RateToggle selectedVisibleRates={visibleRates} onVisibleRatesChange={setVisibleRates} />
        </Stack>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              mobile: "1fr",
              tablet: "repeat(2, minmax(364px, 1fr))",
            },
            gap: 2,
          }}
        >
          {plans.map((plan, index) => (
            <Box
              key={`plan-${index}`}
              sx={{
                gridRow: {
                  mobile: "auto",
                  tablet: index === 0 ? "span 2" : "auto",
                  laptop: "auto",
                },
              }}
            >
              <Box
                sx={{
                  px: 3,
                  py: 3.5,
                  backgroundColor: "white",
                  borderRadius: 2,
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.1)",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Stack gap={2}>
                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      gap={1}
                    >
                      <TierName>{plan.name}</TierName>
                      {visibleRates === "yearly" && plan.price.yearly > 0 && (
                        <Box
                          initial={{ scale: 0.25 }}
                          animate={{ scale: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          component={motion.div}
                          sx={{
                            py: 0.5,
                            px: 1,
                            borderRadius: 4,
                            border: "2px solid",
                            borderColor: "primary.main",
                            color: "primary.main",
                            fontWeight: 600,
                            fontSize: "1.2rem",
                            letterSpacing: "-0.02rem",
                          }}
                        >
                          <Box sx={{ lineHeight: 1.1, fontSize: "1rem" }}>
                            {`Save ${Math.round(
                              ((plan.price.monthly * 12 - plan.price.yearly) / plan.price.yearly) *
                                100,
                            )}%`}
                          </Box>
                        </Box>
                      )}
                    </Stack>
                    <TierPrice price={plan.price} visibleRates={visibleRates} />
                  </Stack>
                  <TierFeatures features={plan.features} />
                </Stack>
                {plan.price.monthly > 0 && plan.price.yearly > 0 && (
                  <TierPurchaseButton
                    entitlement={plan.entitlement}
                    stripePrices={plan.stripeIds}
                    rate={visibleRates}
                  />
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Stack>
    </Container>
  );
};

const RateToggle = (props: {
  selectedVisibleRates: "monthly" | "yearly";
  onVisibleRatesChange: (rate: "monthly" | "yearly") => void;
}) => {
  const handleVisibleRatesChange = () => {
    props.onVisibleRatesChange(props.selectedVisibleRates === "monthly" ? "yearly" : "monthly");
  };
  return (
    <Box
      component="button"
      onClick={handleVisibleRatesChange}
      sx={{
        display: "flex",
        p: 0.5,
        gap: 0.5,
        borderRadius: 6,
        border: "1px solid",
        borderColor: "grey.300",
        backgroundColor: "white",
        width: "fit-content",
      }}
    >
      <Box
        sx={{
          position: "relative",
          px: 1.5,
          py: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          zIndex: 1,
          borderRadius: 6,
          color: props.selectedVisibleRates === "monthly" ? "white" : "grey.700",
          transition: "color 0.1s linear",
          transitionDelay: "0.2s",
        }}
      >
        <Box sx={{ zIndex: 1, position: "relative", lineHeight: 1.3 }}>Monthly</Box>
        {props.selectedVisibleRates === "monthly" && (
          <Box
            component={motion.div}
            sx={{
              zIndex: 0,
              position: "absolute",
              top: 1,
              left: 1,
              height: "100%",
              width: "100%",
              borderRadius: 6,
              backgroundColor: "primary.main",
            }}
            layoutId="selected"
          />
        )}
      </Box>
      <Box
        sx={{
          position: "relative",
          px: 1.5,
          py: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          zIndex: 1,
          borderRadius: 4,
          color: props.selectedVisibleRates === "yearly" ? "white" : "grey.700",
          transition: "color 0.1s linear",
          transitionDelay: "0.2s",
        }}
      >
        <Box sx={{ zIndex: 1, position: "relative", lineHeight: 1.3 }}>Yearly</Box>
        {props.selectedVisibleRates === "yearly" && (
          <Box
            component={motion.div}
            sx={{
              zIndex: 0,
              position: "absolute",
              top: 1,
              left: 1,
              height: "100%",
              width: "100%",
              borderRadius: 4,
              backgroundColor: "primary.main",
            }}
            layoutId="selected"
          />
        )}
      </Box>
    </Box>
  );
};

const featureVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const TierFeatures = (props: { features: React.ReactNode[] }) => (
  <AnimatePresence mode="wait">
    <Stack
      component={motion.ul}
      variants={featureVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 1, staggerChildren: 0.2 }}
      gap={1.5}
    >
      {props.features.map((feat, index) => (
        <Box
          key={`feat-${index}`}
          component={motion.li}
          variants={featureVariants}
          sx={{ fontWeight: 200, fontSize: "1rem", lineHeight: 1.6 }}
        >
          {feat}
        </Box>
      ))}
    </Stack>
  </AnimatePresence>
);

const TierName = (props: { children: React.ReactNode; comingSoon?: boolean }) => (
  <Box sx={{ fontSize: "1.6rem", color: "grey.600", fontWeight: 300 }}>{props.children}</Box>
);

const TierPrice = (props: {
  visibleRates: "monthly" | "yearly";
  price: { monthly: number; yearly: number };
}) => {
  const isMonthly = props.visibleRates === "monthly";
  const isFree = props.price.monthly === 0 || props.price.yearly === 0;
  return (
    <Box sx={{ color: "primary.dark" }}>
      <AnimatePresence mode="wait">
        {isFree ? (
          <Box
            key="free"
            component={motion.div}
            sx={{
              fontSize: "3.5rem",
              letterSpacing: "-0.18rem",
              lineHeight: 1.3,
              fontWeight: 600,
            }}
          >
            Free
          </Box>
        ) : isMonthly ? (
          <Stack
            key="monthly"
            component={motion.div}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            direction="row"
            alignItems="baseline"
          >
            <Box sx={{ fontSize: "2rem", fontWeight: 500 }}>$</Box>
            <Box
              sx={{
                fontSize: "3.5rem",
                letterSpacing: "-0.18rem",
                lineHeight: 1.3,
                fontWeight: 600,
              }}
            >{`${props.price.monthly}`}</Box>
            <Box sx={{ fontSize: "1.2rem" }}>/mo.</Box>
          </Stack>
        ) : (
          <Stack
            key="yearly"
            component={motion.div}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            direction="row"
            alignItems="baseline"
          >
            <Box sx={{ fontSize: "2rem", fontWeight: 500 }}>$</Box>
            <Box
              sx={{
                fontSize: "3.5rem",
                letterSpacing: "-0.18rem",
                lineHeight: 1.3,
                fontWeight: 600,
              }}
            >{`${props.price.yearly}`}</Box>
            <Box sx={{ fontSize: "1.2rem" }}>/yr.</Box>
          </Stack>
        )}
      </AnimatePresence>
    </Box>
  );
};

const TierPurchaseButton = (props: {
  entitlement?: PremiumFeature;
  stripePrices: { monthly: string; yearly: string };
  rate: "monthly" | "yearly";
}) => {
  const [{ user }] = useUser();
  const stripeClaim = useStripeClaim();
  const entitlements = useEntitlements(user);
  const [isRedirecting, setIsRedirecting] = useState(false);

  return !user ? (
    <Box
      sx={{
        color: "grey.500",
        fontWeight: 300,
        textAlign: "center",
        fontSize: "0.8rem",
      }}
    >
      Login to activate subscription
    </Box>
  ) : stripeClaim ? (
    <Box
      component="button"
      onClick={async () => {
        if (!user || !user.uid) return;

        try {
          setIsRedirecting(true);
          const result = await createPortalLink();
          console.log(result);
          if (result) {
            window.location.href = result.data.url;
          }
        } catch (e) {
          setIsRedirecting(false);
        }
      }}
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        fontWeight: 400,
        minWidth: "176px",
        py: 1,
        borderRadius: 2,
        "&:disabled": {
          backgroundColor: "grey.300",
          color: "grey.500",
        },
      }}
    >
      {isRedirecting ? (
        <CircularProgress sx={{ color: "white" }} size={20} />
      ) : (
        "Manage Subscription"
      )}
    </Box>
  ) : props.entitlement && hasPremiumFeature(entitlements, props.entitlement) ? (
    <Box
      sx={{
        color: "grey.500",
        fontWeight: 300,
        textAlign: "center",
        fontSize: "0.8rem",
      }}
    >
      Subscription active through phone app store.
    </Box>
  ) : (
    <Box
      component="button"
      onClick={async () => {
        if (!user || !user.uid) return;
        try {
          setIsRedirecting(true);
          await createCheckoutSession(user.uid, props.stripePrices[props.rate]);
        } catch (e) {
          setIsRedirecting(false);
        }
      }}
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        fontWeight: 400,
        minWidth: "176px",
        py: 1,
        borderRadius: 2,
        "&:disabled": {
          backgroundColor: "grey.300",
          color: "grey.500",
        },
      }}
    >
      {isRedirecting ? <CircularProgress sx={{ color: "white" }} size={20} /> : "Subscribe"}
    </Box>
  );
};

import { AnimatePresence, motion, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";

export const ViewDeferred = <T,>({
  children,
  deferred,
}: {
  children: (props: T) => React.ReactNode;
  deferred: () => Promise<T>;
}) => {
  const [deferredValue, setDeferredValue] = useState<T>({});
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });
  const execute = async () => {
    setDeferredValue(await deferred());
  };
  useEffect(() => {
    if (inView) {
      execute();
    }
  }, [inView]);

  return (
    <AnimatePresence>
      {deferredValue ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.2 } }}
          ref={ref}
        >
          {children(deferredValue)}
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

import { Paper, Stack } from "@mui/material";
import { renderSummaryDate } from "../analysis/ThrowTableInternal";
import { Heavy, Medium } from "../components/Text";
import { ThrowSummary } from "../model/throwSummary";
import { Handedness } from "../model/UserSettings";
import { getThrowName, getVerboseThrowStyle } from "../utils/throw";
import Title from "./Title";

interface ThrowDetailsProps {
  title: string;
  throwSummary?: ThrowSummary;
  handedness: Handedness;
}

function computeBearingDirection(throwSummary?: ThrowSummary) {
  if (throwSummary?.targetBearing != null && throwSummary?.bearing != null) {
    let diff = throwSummary.bearing - throwSummary.targetBearing;
    if (diff <= -180) {
      diff += 360;
    } else if (diff > 180) {
      diff -= 360;
    }
    return diff;
  }
  return null;
}

export const ThrowDetails = (props: ThrowDetailsProps) => {
  const { throwSummary, handedness, title } = props;
  const direction = computeBearingDirection(throwSummary);
  return (
    <Paper
      sx={{
        p: { mobile: 1, tablet: 2 },
        width: "100%",
        overflow: "hidden",
        minHeight: { mobile: "100px" },
      }}
    >
      <Stack height="100%">
        <Title>{title}</Title>
        <Stack justifyContent={"space-between"} height="100%">
          <Heavy
            sx={{
              fontSize: { mobile: "1.4rem", mobileWide: "1.6rem" },
              lineHeight: { mobile: "28px", mobileWide: "36px" },
              fontWeight: 500,
              color: "grey.800",
              mb: 2,
            }}
          >
            {throwSummary &&
              `${getVerboseThrowStyle(throwSummary, handedness)} ${getThrowName(throwSummary, handedness)}`}
          </Heavy>
          {direction != null && (
            <Medium sx={{ fontSize: { mobile: "0.8rem", md: "1rem" }, color: "grey.600" }}>
              {`${Math.abs(direction).toFixed(1)}° to the ${direction > 0 ? "right" : "left"}`}
            </Medium>
          )}
          <Medium sx={{ fontSize: { mobile: "0.8rem", md: "1rem" }, color: "grey.600" }}>
            {throwSummary && renderSummaryDate(throwSummary)}
          </Medium>
        </Stack>
      </Stack>
    </Paper>
  );
};

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useMemo, useState } from "react";
import { getUidQueryParam } from "../../queryUtils";

import { PrimaryThrowType } from "@/dashboard/Tags";
import { Button, Divider, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import { documentId, query, QueryConstraint, Timestamp, where } from "firebase/firestore";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { FlightNumberBoxes, FlightNumberSliders } from "../../dashboard/FlightNumberChooser";
import { LIVE_USER_ID } from "../../dashboard/liveEvents";
import { DEFAULT_PRO_DISC_STATE, flightPathFromSummary } from "../../dashboard/SimDashboard";
import Simulate3D, { FlightPathParams } from "../../dashboard/Simulate3D";
import { getUserThrowSummaries } from "../../firebase/collections";
import { CoreMetrics, ThrowAnalysis } from "../../firebase/converters/analysisSet";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import { useUser } from "../../hooks/useUser";
import { CoreStats } from "../../model/CoreStats";
import { AnalysisType, FlightNumbers, ThrowSummary } from "../../model/throwSummary";
import { Handedness } from "../../model/UserSettings";
import {
  getAnalysisDoc,
  getHyzer,
  getNose,
  getUserId,
  mphToMps,
  storeAnalysisSet,
} from "../../summaryUtils";
import { feetToMeters, mphToKph } from "../../utils/math";
import { useGlobal } from "../providers/GlobalProvider";
import Simulator from "../Simulator";
import ThrowsTable from "../tables/ThrowsTable";
import { ThrowSetMetrics } from "./ThrowSetMetrics";

interface AnalyzeProps {
  userId: string;
  throwSetId?: string;
  handedness?: Handedness;
}

export function computeStat(
  values: CoreMetrics[],
  operator: (a: number[]) => number,
): Omit<CoreMetrics, "originalSummary"> {
  const absHz = operator(values.map((v) => Math.abs(v.rotPerSec ?? 0)));
  const countPosSpin = values.filter((v) => v.rotPerSec && v.rotPerSec >= 0).length;
  const countNegSpin = values.filter((v) => v.rotPerSec && v.rotPerSec < 0).length;
  const signedHz = countPosSpin >= countNegSpin ? absHz : -absHz;

  // compute the primaryType of the throw by using the one with the most occurrences
  // first bucket by primary type
  const primaryTypeMap = new Map<PrimaryThrowType, number>();
  values.forEach((v) => {
    const type = v.primaryType;
    if (!type) {
      return;
    }
    primaryTypeMap.set(type, (primaryTypeMap.get(type) ?? 0) + 1);
  });

  let primaryType: PrimaryThrowType = "RHBH";
  if (primaryTypeMap.size > 0) {
    primaryType = Array.from(primaryTypeMap.entries()).reduce(
      (a, b) => (a[1] > b[1] ? a : b),
      ["RHBH", 0],
    )[0] as PrimaryThrowType;
  }

  // do the same as primary type for handedness
  const handednessMap = new Map<Handedness, number>();

  values.forEach((v) => {
    const type = v.handedness;
    if (!type) {
      return;
    }
    handednessMap.set(type, (handednessMap.get(type) ?? 0) + 1);
  });

  let handedness: Handedness = Handedness.RIGHT;

  if (handednessMap.size > 0) {
    handedness = Array.from(handednessMap.entries()).reduce(
      (a, b) => (a[1] > b[1] ? a : b),
      ["RIGHT", 0],
    )[0] as Handedness;
  }

  return {
    hyzerAngle: operator(values.map((v) => v.hyzerAngle)),
    correctedHyzerAngle: operator(values.map((v) => v.correctedHyzerAngle ?? 0)),
    noseAngle: operator(values.map((v) => v.noseAngle)),
    correctedNoseAngle: operator(values.map((v) => v.correctedNoseAngle ?? 0)),
    rotPerSec: signedHz,
    speedMph: operator(values.map((v) => v.speedMph)),
    speedKmh: operator(values.map((v) => (v.speedMph ? mphToKph(v.speedMph) : NaN))),
    spinRpm: Math.abs(signedHz * 60),
    advanceRatio: operator(values.map((v) => v.advanceRatio ?? 0)),
    launchAngle: operator(values.map((v) => v.launchAngle)),
    wobble: operator(values.map((v) => v.wobble)),
    uphillAngle: operator(values.map((v) => v.uphillAngle)),
    offAxisDegrees: operator(values.map((v) => v.offAxisDegrees)),
    estimatedFeet: operator(values.map((v) => v.estimatedFeet ?? 0)),
    distanceFeet: operator(values.map((v) => v.estimatedFeet ?? 0)),
    distanceMeters: operator(
      values.map((v) => (v.estimatedFeet ? feetToMeters(v.estimatedFeet) : 0)),
    ),
    throwTime: Timestamp.fromMillis(
      values.map((v) => v.throwTime.toDate().valueOf()).reduce((a, b) => a + b, 0) / values.length,
    ),
    upsideDown:
      values.filter((v) => v.upsideDown).length > values.filter((v) => !v.upsideDown).length,
    // primaryType: primaryType,
    handedness: handedness,
  };
}

export function computeAvgStat(values: CoreMetrics[]): CoreMetrics {
  return computeStat(values, (a) => a.reduce((a, b) => a + b, 0) / a.length);
}

export function useAnalysisSet(userId: string, ids: string[] | undefined = undefined) {
  const q = useMemo(() => {
    if (userId === undefined || !ids?.length) {
      return undefined;
    }
    const q1 = getUserThrowSummaries(userId);
    const filters: QueryConstraint[] = [];
    if (ids.length > 30) {
      // NB: firebase in query has a limit of 30
      console.error("Too many ids");
      ids = ids.slice(0, 30);
    }
    filters.push(where(documentId(), "in", ids));
    return query(q1, ...filters);
  }, [userId, ids]);
  return useCollectionData(q);
}

function CreateFlightNumberPopover(props: { data: ThrowAnalysis; flightNumbers: FlightNumbers }) {
  const { data, flightNumbers } = props;
  const [disc, setDisc] = useState(flightNumbers);
  return (
    <PopupState variant="popover" popupId="analyzeSetFlightNums">
      {(popupState) => (
        <>
          <Button {...bindTrigger(popupState)} variant="contained">
            Change Flight Numbers
          </Button>
          <Popover
            {...bindPopover(popupState)}
            onClose={() => {
              if (flightNumbers !== disc) {
                storeAnalysisSet(getUserId(), data.id, { flightNumbers: disc });
              }
              popupState.close();
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <FlightNumberSliders flight_numbers={disc} setFlightNumbers={setDisc} />
          </Popover>
        </>
      )}
    </PopupState>
  );
}

function AnalyzeDashboardView(props: {
  throws: ThrowSummary[];
  throwSet: ThrowAnalysis;
  userId: string;
  handedness?: Handedness;
}) {
  const { throwSet, userId, throws, handedness } = props;
  const [{ user }] = useUser();
  const { "kiosk-unity": kioskUnity } = useFeatureFlags(user);

  // find first estimated flight numbers not null
  const disc =
    throwSet?.flightNumbers ??
    throws.find((v) => v.estimatedFlightNumbers)?.estimatedFlightNumbers ??
    DEFAULT_PRO_DISC_STATE;

  const discs: FlightNumbers[] = [];
  [3, 12].forEach((speed) => {
    [-5, -3, 0].forEach((turn) => {
      discs.push({
        speed: speed,
        glide: 5,
        turn: turn,
        weight: 0.175,
      });
    });
  });

  const flightPathsRaw: FlightPathParams[] = throws.map((v) => {
    return { ...flightPathFromSummary(v), flight_numbers: disc ?? DEFAULT_PRO_DISC_STATE };
  });

  const flightPaths = flightPathsRaw.slice(0, 10);

  return (
    <Container
      sx={{
        mt: { mobile: 2, md: 4 },
        mb: 4,
        display: "flex",
        flexDirection: "column",
        gap: { mobile: 1.5, md: 2 },
      }}
    >
      <ThrowSetMetrics throws={throws} tags={throwSet?.tagIntersect ?? []} throwSet={throwSet} />
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {kioskUnity ? (
          <Simulator flights={throws} mode="multiple" userId={userId} />
        ) : (
          <>
            <Simulate3D flightParams={flightPaths} isFlightPathMode={true} />
            <Stack p={2} gap={2}>
              <Stack direction="row" spacing={2} width="100%">
                <Button
                  variant="contained"
                  endIcon={<OpenInNewIcon />}
                  color="primary"
                  onClick={() => {
                    const url =
                      "simulate?" + getUidQueryParam() + convertToQueryString(throwSet.avg!);
                    window.open(url, "_blank", "noreferrer");
                  }}
                >
                  View average throw in simulator
                </Button>
                <CreateFlightNumberPopover data={throwSet} flightNumbers={disc} />
              </Stack>
              <Divider sx={{ opacity: 0.5 }} />
              <FlightNumberBoxes includeWeight={false} flight_numbers={disc} />
            </Stack>
          </>
        )}
      </Paper>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <ThrowsTable throws={throws} devices={new Map()} display="default" id="analyze-dashboard" />
      </Paper>
    </Container>
  );
}

function convertToQueryString(params: CoreStats): string {
  const obj: Partial<FlightPathParams> = {
    hyzer_degrees: getHyzer(params),
    nose_up_degrees: getNose(params),
    uphill_degrees: params.uphillAngle,
    v: params.speedMph * mphToMps,
    spin: -(params.rotPerSec ?? 0) * 2 * Math.PI,
  };
  // @ts-ignore
  return new URLSearchParams(obj).toString();
}

export function AnalyzeDashboard(props: AnalyzeProps) {
  const { userId, throwSetId } = props;

  const docId = useMemo(() => {
    if (userId && throwSetId) {
      return getAnalysisDoc(userId, throwSetId);
    }
    return null;
  }, [throwSetId, userId]);

  const [throwSet] = useDocumentData<ThrowAnalysis>(docId);
  const [throws] = useAnalysisSet(userId, throwSet?.ids);

  if (!throws) {
    return null;
  }

  const filtered = throws?.filter((v) => !v.loading);

  if (filtered.length === 0) {
    return null;
  }

  return throwSet ? (
    <AnalyzeDashboardView userId={userId} throwSet={throwSet} throws={filtered} />
  ) : null;
}

export function ShareAnalyzeDashboard(props: {
  throwSet: ThrowAnalysis;
  throws: ThrowSummary[];
  throwsLoading: boolean;
}) {
  const { throwSet, throws, throwsLoading } = props;
  const { externalUserId } = useGlobal();

  return throwsLoading || !externalUserId ? null : (
    <AnalyzeDashboardView throwSet={throwSet} userId={externalUserId} throws={throws} />
  );
}

export function DynamicAnalyzeDashboard() {
  const params = useParams();
  const ids = params["*"]?.split("/") ?? [];
  const [docs] = useAnalysisSet(LIVE_USER_ID, ids);
  if (!docs) {
    return null;
  }
  const filtered = docs.filter((v) => !v.loading);

  if (filtered.length === 0) {
    return null;
  }

  const data: Partial<ThrowAnalysis> = {
    id: "dynamic",
    ids,
    createTime: Timestamp.now(),
    type: AnalysisType.MANUAL,
  };

  return (
    <AnalyzeDashboardView
      throwSet={data as ThrowAnalysis}
      userId={LIVE_USER_ID}
      throws={filtered}
    />
  );
}

import React, { useEffect } from "react";
import { createPortalLink } from "../../stripe";

export const ONLY_COMMERCIAL_PRICING_TABLE = "prctbl_1OFkZsFBIwpy1XnfvJVtzARz";
export const ALL_THREE_PRICING_TABLE = "prctbl_1QGOLpFBIwpy1XnfYxgymTCL";
export const PLAY_AND_PRO_PRICING_TABLE = "prctbl_1QLTUwFBIwpy1XnfUrywWP53";

const StripePricingTable = (props: {
  pricingTable?: string;
  email?: string | undefined | null;
}) => {
  const { pricingTable = ALL_THREE_PRICING_TABLE, email } = props;
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  // client-reference-id
  // createPortalLink();
  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": pricingTable,
    "customer-email": email,
    "publishable-key":
      "pk_live_51NZgfZFBIwpy1XnfOTt5RoMTKk8wKpZePnqMij7Yueakk7P2mXK7a1LP9T6onDAgGfLCztGlHOgP0WdA3GfStI1n00PprjApR0",
  });
};
export default StripePricingTable;

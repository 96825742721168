import { ClusteredStorePins } from "@/components/stores/ClusteredStorePins";
import { StoreInfoCard } from "@/components/stores/StoreInfoCard";
import StoreMapLegend from "@/components/stores/StoreMapLegend";
import StoreMap from "@/dashboard/StoreMap";
import { StoreMetadata } from "@/firebase/converters/leaderboardStoreMetadata";
import { Box } from "@mui/system";

export interface StoresMapProps {
  selectedStore?: StoreMetadata;
  setSelectedStore: React.Dispatch<React.SetStateAction<StoreMetadata | null>>;
  visibleStores?: StoreMetadata[];
}

export function StoresMap({ selectedStore, setSelectedStore, visibleStores }: StoresMapProps) {
  return (
    <StoreMap>
      <StoreMapLegend
        sx={{
          position: "absolute",
          top: 12,
          right: 12,
          display: { mobile: "none", tablet: "block" },
        }}
      />
      {selectedStore && (
        <Box
          sx={{
            position: "absolute",
            top: { mobile: 12, tablet: 16 },
            left: { mobile: 12, tablet: 16 },
            right: { mobile: 12, tablet: "auto" },
            minWidth: { tablet: "320px" },
            maxWidth: { tablet: "412px" },
          }}
        >
          <StoreInfoCard store={selectedStore} onClose={() => setSelectedStore(null)} />
        </Box>
      )}
      <Box sx={{ position: "absolute", bottom: 12, left: 12 }}>
        <Box
          component="img"
          src="https://storage.googleapis.com/techdisc-cdn/logo_assets/TechDiscLight_onlyWords.svg"
          sx={{
            maxWidth: { md: "256px", mobile: "112px" },
            filter: "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))",
          }}
        />
      </Box>
      <ClusteredStorePins
        stores={visibleStores ?? []}
        selectedStore={selectedStore}
        setSelectedStore={setSelectedStore}
      />
    </StoreMap>
  );
}

import {
  getAdminSimulatorLayoutCollection,
  getLayoutLeaderboardCollection,
} from "@/firebase/collections";
import type { StrokePlayLeaderboardEntry } from "@/model/leaderboard";
import { limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useLayoutLeaderboard = (
  layoutId?: string,
): [StrokePlayLeaderboardEntry[], boolean] => {
  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState<StrokePlayLeaderboardEntry[]>([]);

  const fetchLeaderboards = (layoutId: string, _limit: number = 10) => {
    setLoading(true);
    const q = query(
      getLayoutLeaderboardCollection(layoutId),
      orderBy("totalStrokes", "asc"),
      limit(_limit),
    );
    const unsub = onSnapshot(q, (snapshot) => {
      const entries = snapshot.docs.map((doc) => doc.data() as StrokePlayLeaderboardEntry);
      setEntries(entries);
      setLoading(false);
    });
    return () => unsub();
  };

  useEffect(() => {
    if (layoutId) {
      fetchLeaderboards(layoutId);
    }
  }, [layoutId]);

  return [entries, loading];
};

export const useLayoutLeaderboards = (): [Map<string, StrokePlayLeaderboardEntry[]>, boolean] => {
  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState<Map<string, StrokePlayLeaderboardEntry[]>>(new Map());

  useEffect(() => {
    const fetchLayoutLeaderboards = () => {
      try {
        setLoading(true);
        return onSnapshot(getAdminSimulatorLayoutCollection(), (layoutSnapshot) => {
          const layoutIds = layoutSnapshot.docs.map((doc) => doc.id);

          layoutIds.map((layoutId) => {
            const q = query(
              getLayoutLeaderboardCollection(layoutId),
              orderBy("totalStrokes", "asc"),
            );
            const unsubscribeLayouts = onSnapshot(q, (layoutBoardSnapshot) => {
              const entries = layoutBoardSnapshot.docs.map(
                (doc) => doc.data() as StrokePlayLeaderboardEntry,
              );
              setEntries((prev) => {
                const newEntries = new Map(prev);
                newEntries.set(layoutId, entries);
                return newEntries;
              });
            });
            return () => {
              unsubscribeLayouts();
            };
          });
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchLayoutLeaderboards();
  }, []);

  return [entries, loading];
};

import { PlayEventBanner } from "@/components/banners/PlayEventBanner";
import { getLiveEventsCollection, getPlayEventsCollection } from "@/firebase/collections";
import { addEventStatus } from "@/utils/events";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { TechDiscBanner } from "../../dashboard/ShopBanner";
import useLive from "../../hooks/useLive";
import { useUser } from "../../hooks/useUser";
import { LiveBanner } from "../banners/LiveBanner";

export default function UnauthenticatedBanner() {
  const { isLiveRoute } = useLive();
  const [{ user, userLoading }] = useUser();
  const [liveEventDocs = [], liveEventsLoading] = useCollectionData(getLiveEventsCollection());
  const [playEventDocs = [], playEventsLoading] = useCollectionData(getPlayEventsCollection());
  const liveEvents = liveEventDocs.map(addEventStatus);
  const playEvents = playEventDocs.map(addEventStatus);

  const isLiveEventLiveOrUpcoming = liveEvents?.some((event) => event.isLive || event.isPreview);

  const isPlayEventLiveOrUpcoming = playEvents?.some((event) => event.isPreview || event.isLive);

  if (liveEventsLoading || playEventsLoading || userLoading) {
    return null;
  } else if (isLiveRoute || isLiveEventLiveOrUpcoming) {
    return <LiveBanner liveEvents={liveEvents} />;
  } else if (isPlayEventLiveOrUpcoming) {
    return <PlayEventBanner playEvents={playEvents} />;
  } else {
    return !user ? <TechDiscBanner /> : null;
  }
}

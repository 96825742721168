import { getDisplayNamePlaceholder } from "@/components/leaderboard/fakeNames";
import { getGolfScore } from "@/components/leaderboards/utils";
import { ViewDeferred } from "@/components/routes/LazyUser";
import { columnHelper, StrokePlayTable } from "@/components/routes/StrokePlayTable";
import { getPlayEventsCollection } from "@/firebase/collections";
import { useEventLeaderboard } from "@/hooks/useLeaderboards/useEventLeaderboards";
import { useUser } from "@/hooks/useUser";
import type { StrokePlayLeaderboardEntry } from "@/model/leaderboard";
import { getLeaderboardUserMetadata } from "@/summaryUtils";
import type { PlayEvent } from "@/types/events";
import { nthNumber } from "@/utils/common";
import { Avatar, Box, Stack } from "@mui/material";
import { differenceInCalendarDays } from "date-fns/esm";
import { getDoc } from "firebase/firestore";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo, useState, type HTMLAttributes } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { StrokePlayScorecard } from "../leaderboards/StrokePlayScorecard";

const layoutVariants = {
  initial: {},
  animate: { transition: { staggerChildren: 0.5 } },
};

export default function PlayEventLeaderboardRoute() {
  const params = useParams();

  const [playEvents = [], playEventsLoading] = useCollectionData(getPlayEventsCollection());
  const currentEvent = playEvents?.find((event) => event.id === params.eventId);
  const [{ userId }] = useUser();
  const [entries, entriesLoading] = useEventLeaderboard(currentEvent);
  const [selectedDayEntries, setSelectedDayEntries] = useState<StrokePlayLeaderboardEntry[]>([]);
  const [selectedDay, setSelectedDay] = useState(0);
  const numDays =
    differenceInCalendarDays(currentEvent?.endDate?.toDate(), currentEvent?.startDate?.toDate()) +
    1;
  const leaderboardEntries = useMemo(() => {
    return selectedDay === 0 ? entries.layout : selectedDayEntries;
  }, [entries, selectedDayEntries, selectedDay]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("rank", {
        header: () => (
          <Box
            sx={{
              color: "grey.50",
            }}
          >
            Rank
          </Box>
        ),
        cell: ({ row }) => (
          <Stack
            sx={{
              fontSize: "1.2rem",
              fontWeight: 400,
              color: "grey.600",
            }}
          >
            {row.original.rank}
          </Stack>
        ),
        minSize: 64,
        maxSize: 64,
      }),
      columnHelper.accessor("displayName", {
        header: () => (
          <Box
            sx={{
              color: "grey.50",
            }}
          >
            Name
          </Box>
        ),
        cell: ({ row }) => (
          <ViewDeferred
            key={row.original.userId}
            deferred={async () => {
              const metadataDoc = await getDoc(getLeaderboardUserMetadata(row.original.userId));
              const metadata = metadataDoc.data();
              return metadata;
            }}
          >
            {(metadata) => {
              console.log(
                metadata?.name,
                row.original.displayName,
                getDisplayNamePlaceholder(row.original.userId),
              );
              return (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Avatar
                    src={metadata?.photo}
                    alt={
                      metadata && metadata?.name
                        ? metadata.name
                        : row.original.displayName
                          ? row.original.displayName
                          : getDisplayNamePlaceholder(row.original.userId)
                    }
                    sx={{
                      border: "1px solid",
                      borderColor: "primary.light",
                    }}
                  />

                  <Stack gap={0.5}>
                    <Box sx={{ fontSize: "1rem", fontWeight: 300 }}>
                      {metadata && metadata?.name
                        ? metadata.name
                        : row.original.displayName
                          ? row.original.displayName
                          : getDisplayNamePlaceholder(row.original.userId)}
                    </Box>
                  </Stack>
                </Stack>
              );
            }}
          </ViewDeferred>
        ),
      }),
      columnHelper.accessor("scorePerHole", {
        header: () => (
          <Box
            sx={{
              color: "grey.50",
            }}
          >
            Overall
          </Box>
        ),
        minSize: 64,
        maxSize: 64,
        cell: ({ row }) => (
          <Stack
            sx={{
              fontSize: { mobile: "1.25rem", laptop: "1.8rem" },
              fontWeight: 300,
              width: "100%",
              textAlign: "right",
            }}
          >
            {getGolfScore(row.original.scorePerHole.reduce((acc, score) => acc + score, 0))}
          </Stack>
        ),
      }),
    ],
    [entries],
  );

  const userEntry = useMemo(() => {
    return leaderboardEntries?.find((entry) => entry.userId === userId);
  }, [leaderboardEntries, userId]);

  const containerVariants = {
    initial: { y: 10 },
    enter: { y: 0, transition: { duration: 0.2, ease: "easeInOut" } },
    exit: { y: 10, transition: { duration: 0.2, ease: "easeInOut" } },
  };

  return (
    <AnimatePresence mode="wait">
      {entriesLoading || playEventsLoading ? (
        "Loading..."
      ) : (
        <Stack
          component={motion.div}
          variants={containerVariants}
          initial="initial"
          animate="enter"
          exit="exit"
          alignItems="center"
          sx={{
            backgroundColor: "#202123",
            borderRadius: 1,
            p: { mobile: 2, tablet: 4, laptop: 8 },
            gap: 6,
          }}
        >
          <Stack sx={{ width: "100%", gap: 4, alignItems: "center" }}>
            <Box
              component="img"
              sx={{ backgroundColor: "#202123", width: "90%", borderRadius: 1 }}
              src="https://storage.googleapis.com/techdisc-cdn/logo_assets/ledgestone/LedgestoneLight.svg"
            />
            <Stack
              direction="row"
              sx={{
                width: "100%",
                maxWidth: "768px",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
              gap={4}
            >
              <LeaderboardOption
                event={currentEvent}
                onClick={() => {
                  setSelectedDayEntries([]);
                  setSelectedDay(0);
                }}
                isSelected={selectedDay === 0}
              >
                Overall
              </LeaderboardOption>
              {Array.from({
                length: numDays,
              }).map((_, index) => (
                <LeaderboardOption
                  key={index}
                  event={currentEvent}
                  isSelected={selectedDay === index + 1}
                  disabled={entries.daily?.[index]?.length === 0}
                  onClick={() => {
                    setSelectedDayEntries(entries.daily?.[index]);
                    setSelectedDay(index + 1);
                  }}
                >
                  Day {index + 1}
                </LeaderboardOption>
              ))}
            </Stack>
          </Stack>
          <Stack
            key={`leaderboard-${selectedDay}`}
            direction={{ mobile: "column", laptop: "row" }}
            sx={{
              gap: 4,
              alignItems: { mobile: "center", laptop: "flex-start" },
              justifyContent: { mobile: "inherit", laptop: "center" },
              width: "100%",
            }}
          >
            <Stack
              component={motion.div}
              variants={layoutVariants}
              initial="initial"
              animate="animate"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100%",
                gap: { mobile: 6, desktop: 2 },
              }}
            >
              {leaderboardEntries
                ?.slice(0, 3)
                .map((entry) => <StrokePlayScorecard key={entry.id} entry={entry} />)}
            </Stack>
            <StrokePlayTable columns={columns} data={leaderboardEntries?.slice(3, 10)} />
          </Stack>
          {userEntry && (
            <Stack
              gap={4}
              sx={{
                width: "100%",
                pt: { mobile: 2, laptop: 6 },
                borderTop: "2px solid",
                borderColor: currentEvent?.branding?.primaryColor,
              }}
              alignItems="center"
              justifyContent="center"
            >
              <Stack direction={{ mobile: "column", tablet: "row" }} gap={4}>
                <Stack alignItems={{ mobile: "center", laptop: "flex-start" }}>
                  <Box
                    sx={{
                      color: "white",
                      fontSize: "1.8rem",
                      fontWeight: 600,
                      letterSpacing: -0.1,
                      lineHeight: 0.9,
                    }}
                  >
                    YOUR
                  </Box>
                  <Box
                    sx={{
                      color: "white",
                      fontSize: "1.8rem",
                      fontWeight: 600,
                      letterSpacing: -0.1,
                      lineHeight: 0.9,
                    }}
                  >
                    CURRENT
                  </Box>
                  <Box
                    sx={{
                      color: "white",
                      fontSize: "1.8rem",
                      fontWeight: 600,
                      letterSpacing: -0.1,
                      lineHeight: 0.9,
                    }}
                  >
                    RANK
                  </Box>
                </Stack>
                {userEntry.rank > 3 && (
                  <Box
                    sx={{
                      fontSize: "3rem",
                      fontWeight: 500,
                      color: currentEvent?.branding?.primaryColor,
                    }}
                  >
                    {`${userEntry.rank}${nthNumber(userEntry.rank)}`}
                  </Box>
                )}
              </Stack>

              <StrokePlayScorecard entry={userEntry} />
            </Stack>
          )}
        </Stack>
      )}
    </AnimatePresence>
  );
}

const LeaderboardOption = (
  props: {
    children: React.ReactNode;
    event?: PlayEvent;
    isSelected: boolean;
  } & HTMLAttributes<HTMLButtonElement>,
) => {
  const { children, event, isSelected, ...rest } = props;
  return (
    <Box
      component="button"
      sx={{
        position: "relative",
        color: props.disabled ? "grey.600" : "grey.50",
        fontSize: { mobile: "1rem", laptop: "1.2rem" },
      }}
      {...rest}
    >
      {isSelected && (
        <Box
          component={motion.div}
          sx={{
            zIndex: 0,
            position: "absolute",
            top: 4,
            left: 1,
            height: "100%",
            width: "100%",
            borderBottom: "2px solid",
            borderColor: event?.branding?.primaryColor ?? "primary.main",
          }}
          layoutId="leaderboard-option-select"
          layout="position"
        />
      )}
      {children}
    </Box>
  );
};

import { useGlobal } from "../../components/providers/GlobalProvider";
import { UserRole } from "../../components/user/types";
import { FeatureFlag } from "../../featureFlags";

export const usePermissions = (opts?: {
  requiredRoles?: UserRole[];
  requiredFeatureFlags?: FeatureFlag[];
}) => {
  const { requiredRoles, requiredFeatureFlags } = opts ?? {
    requiredRoles: [],
    requiredFeatureFlags: [],
  };
  const { user, userRolesLoading, userRoles, featureFlags } = useGlobal();

  const passesRoleCheck =
    (requiredRoles?.length ?? 0 > 0)
      ? requiredRoles?.some((role) => userRoles.includes(role))
      : true;

  const passesFeatureFlagCheck =
    (requiredFeatureFlags?.length ?? 0 > 0)
      ? requiredFeatureFlags?.every((flag) => featureFlags[flag])
      : true;

  const isAuthorized = passesRoleCheck && passesFeatureFlagCheck;
  return { user, isLoading: userRolesLoading, isAuthorized };
};

import PlayLayouts from "@/components/leaderboards/PlayLayouts";
import { containerVariants } from "@/components/routes/variants";
import { Stack } from "@mui/system";
import { AnimatePresence, motion } from "framer-motion";
import { useCollectionData } from "react-firebase-hooks/firestore";
import PlayEvents from "../leaderboards/PlayEvents";

import { getAdminSimulatorLayoutCollection, getPlayEventsCollection } from "@/firebase/collections";
import { useLayoutLeaderboards } from "@/hooks/useLeaderboards/useLayoutLeaderboard";
import { query, where } from "firebase/firestore";
import { useMemo } from "react";

export default function PlayLeaderboardsRoute() {
  const [playEvents = [], playEventsLoading] = useCollectionData(getPlayEventsCollection());
  const [layouts, layoutsLoading] = useCollectionData(
    query(
      getAdminSimulatorLayoutCollection(),
      where("isPublic", "==", true),
      where("isEventLayout", "==", false),
    ),
  );
  const [layoutLeaderboards, layoutLeaderboardsLoading] = useLayoutLeaderboards();

  const layoutsWithLeaderboards = useMemo(
    () =>
      layouts
        ?.map((layout) =>
          layoutLeaderboards?.get(layout?.id)?.length === 0
            ? null
            : {
                ...layout,
                leaderboard: layoutLeaderboards.get(layout.id),
              },
        )
        .filter((layout) => layout),
    [layouts, layoutLeaderboards],
  );

  const isLoading = layoutsLoading || layoutLeaderboardsLoading || playEventsLoading;
  return (
    <AnimatePresence mode="wait">
      <Stack
        direction={{ mobile: "column", tablet: "row" }}
        component={motion.div}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        sx={{
          gap: 8,
          justifyContent: "center",
          mx: "auto",
          width: "100%",
        }}
      >
        {isLoading ? null : (
          <>
            <PlayEvents playEvents={playEvents} />
            <PlayLayouts layouts={layoutsWithLeaderboards} />
          </>
        )}
      </Stack>
    </AnimatePresence>
  );
}

import { Box, Stack } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Black } from "../../Text";

export const StockShotIntro = () => {
  const { t } = useTranslation();
  return (
    <Stack
      direction={{ mobile: "column", laptop: "row" }}
      flexBasis={{ tablet: "40%" }}
      gap={{ mobile: 4, tablet: 4 }}
    >
      <Stack gap={{ mobile: 3, tablet: 2 }} flexBasis={{ tablet: "50%" }}>
        <Stack direction="row" justifyContent="space-between">
          <Black size={{ mobile: 16, tablet: 20 }} color="grey.600" spacing="dense">
            {t("throwProfile.information.whatAreStockShots.heading")}
          </Black>
        </Stack>
        <Stack
          gap={{ mobile: 2, tablet: 2 }}
          component="span"
          sx={{
            lineHeight: { mobile: "1.6em", tablet: "1.6em" },
            letterSpacing: { mobile: "0.01em", tablet: "0.01em" },
            fontSize: { mobile: 14, tablet: 14 },
            fontWeight: 300,
            color: "grey.700",
          }}
        >
          <span>
            <Trans i18nKey="throwProfile.information.whatAreStockShots.info.0">
              <b>stock shot</b>
            </Trans>
          </span>
          <Trans i18nKey="throwProfile.information.whatAreStockShots.info.1">
            Criteria
            <Stack component="ul" gap={0.5}>
              <Box component="li" sx={{ fontWeight: 400 }}>
                throwing arm/hand
              </Box>
              <Box component="li" sx={{ fontWeight: 400 }}>
                throwing style
              </Box>
              <Box component="li" sx={{ fontWeight: 400 }}>
                shot type
              </Box>
            </Stack>
          </Trans>
        </Stack>
      </Stack>
      <Stack gap={{ mobile: 2, tablet: 2 }} flexBasis={{ tablet: "50%" }}>
        <Black size={{ mobile: 16, tablet: 20 }} color="grey.600" spacing="dense">
          {t("throwProfile.information.howToUseStockShots.heading")}
        </Black>
        <Stack
          gap={{ mobile: 2, tablet: 2 }}
          component="span"
          sx={{
            lineHeight: { mobile: "1.6em", tablet: "1.6em" },
            letterSpacing: { mobile: "0.01em", tablet: "0.01em" },
            fontSize: { mobile: 14, tablet: 14 },
            fontWeight: 300,
            color: "grey.700",
          }}
        >
          <span>
            <Trans i18nKey="throwProfile.information.howToUseStockShots.info.0">
              <b>disc collection</b>
            </Trans>
          </span>
          {t("throwProfile.information.howToUseStockShots.info.1")}
        </Stack>
      </Stack>
    </Stack>
  );
};

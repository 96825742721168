import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { NavigationItemProps } from "./types";

export function NavigationItem({ label, isSelected, onClick }: NavigationItemProps) {
  return (
    <Box
      sx={{
        position: "relative",
        px: 1.5,
        py: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        zIndex: 1,
        borderRadius: 6,
        color: isSelected ? "grey.800" : "grey.600",
        fontWeight: isSelected ? 600 : 300,
        userSelect: "none",
        "&:hover": {
          color: isSelected ? "grey.800" : "grey.700",
        },
      }}
    >
      <Box
        component="button"
        onClick={onClick}
        sx={{
          zIndex: 1,
          position: "relative",
          lineHeight: 1.3,
          fontSize: "1.2rem",
          letterSpacing: -0.2,
        }}
      >
        {label}
      </Box>
      {isSelected && (
        <Box
          component={motion.div}
          sx={{
            zIndex: 0,
            position: "absolute",
            top: 1,
            left: 1,
            height: "100%",
            width: "100%",
            borderBottom: "2px solid",
            borderColor: "primary.main",
          }}
          layoutId="selected"
          layout="position"
        />
      )}
    </Box>
  );
}

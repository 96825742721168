import { PremiumStorePinCluster } from "@/components/icons/PremiumStorePinCluster";
import { StorePinCluster } from "@/components/icons/StorePinCluster";
import { Cluster, ClusterStats, Renderer } from "@googlemaps/markerclusterer";
import { createRoot } from "react-dom/client";

export class PreservePinsRenderer implements Renderer {
  render(
    cluster: Cluster,
    stats: ClusterStats,
    map: google.maps.Map,
  ): google.maps.marker.AdvancedMarkerElement {
    const premium = cluster.markers?.some((marker) => marker && marker?.["data-premium"]);
    const glyphElement = document.createElement("div");
    glyphElement.style.width = "44px";
    glyphElement.style.height = "53px";

    createRoot(glyphElement).render(premium ? <PremiumStorePinCluster /> : <StorePinCluster />);

    return new google.maps.marker.AdvancedMarkerElement({
      map,
      collisionBehavior: google.maps.CollisionBehavior.REQUIRED,
      position: cluster.position,
      content: glyphElement,
      zIndex: premium ? 1 : 0 - cluster.position.lat(),
    });
  }
}

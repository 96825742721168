import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { motion } from "framer-motion";
import { t } from "i18next";
import { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { Controller, UseFormReturn } from "react-hook-form";
import { DiscAppearance, DiscClass, DiscWear, FlightPlate } from "../../model/discs";
import { Medium, Strong } from "../Text";
import DiscAutocompleteInput from "./DiscAutocompleteInput";
import { DiscPresetFields } from "./DiscPresetForm";
import DiscRender from "./DiscRender";
import discData from "./discData";

export interface CustomizeDiscFormProps {
  methods: UseFormReturn<DiscPresetFields>;
}

function CustomizeDiscForm(props: CustomizeDiscFormProps) {
  const { methods } = props;
  const [colorSelection, setColorSelection] = useState<"primary" | "secondary">("primary");

  const selectedDiscClass = methods.watch("class");
  const selectedColors = methods.watch("colors");
  const selectedAppearance = methods.watch("appearance");
  const selectedManufacturer = methods.watch("manufacturer");

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { tablet: "repeat(2, 1fr)", mobile: "1fr" },
        gridAutoRows: "min-content",
        gap: 2,
      }}
    >
      <Stack component={Paper} elevation={0} gap={2} p={2} sx={{ backgroundColor: "grey.50" }}>
        <FormControl sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Medium>{t("class")}</Medium>
          <Controller
            control={methods.control}
            name="class"
            render={({ field }) => (
              <TextField
                select
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  fullWidth: true,
                  ...field,
                }}
                {...field}
              >
                <MenuItem value={DiscClass.Putter}>{t("discClass.proper.putter_one")}</MenuItem>
                <MenuItem value={DiscClass.PuttApproach}>
                  {t("discClass.proper.putt-approach_one")}
                </MenuItem>
                <MenuItem value={DiscClass.Midrange}>{t("discClass.proper.midrange_one")}</MenuItem>
                <MenuItem value={DiscClass.Fairway}>{t("discClass.proper.fairway_one")}</MenuItem>
                <MenuItem value={DiscClass.Control}>{t("discClass.proper.control_one")}</MenuItem>
                <MenuItem value={DiscClass.Distance}>{t("discClass.proper.distance_one")}</MenuItem>
              </TextField>
            )}
          />
        </FormControl>
        <FormControl sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Medium>{t("wear")}</Medium>
          <Controller
            control={methods.control}
            name="wear"
            render={({ field }) => (
              <TextField
                select
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  fullWidth: true,
                  ...field,
                }}
                {...field}
              >
                <MenuItem value={DiscWear.New}>{t("discWear.new")}</MenuItem>
                <MenuItem value={DiscWear.FieldTested}>{t("discWear.fieldTested")}</MenuItem>
                <MenuItem value={DiscWear.Seasoned}>{t("discWear.seasoned")}</MenuItem>
                <MenuItem value={DiscWear.Worn}>{t("discWear.worn")}</MenuItem>
              </TextField>
            )}
          />
        </FormControl>
        <FormControl sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Medium>{t("flightPlate")}</Medium>
          <Controller
            control={methods.control}
            name="flightPlate"
            render={({ field }) => (
              <TextField
                select
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  fullWidth: true,
                  ...field,
                }}
                {...field}
              >
                <MenuItem value={FlightPlate.Puddle}>{t("discFlightPlate.puddle")}</MenuItem>
                <MenuItem value={FlightPlate.Flat}>{t("discFlightPlate.flat")}</MenuItem>
                <MenuItem value={FlightPlate.Neutral}>{t("discFlightPlate.neutral")}</MenuItem>
                <MenuItem value={FlightPlate.Dome}>{t("discFlightPlate.dome")}</MenuItem>
              </TextField>
            )}
          />
        </FormControl>
        <FormControl sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Medium>{t("manufacturer")}</Medium>
          <DiscAutocompleteInput
            methods={methods}
            name="manufacturer"
            data={discData.map((disc) => disc.name)}
          />
        </FormControl>
        <FormControl sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Medium>{t("mold")}</Medium>
          <DiscAutocompleteInput
            methods={methods}
            name="mold"
            data={discData
              .filter((data) => (selectedManufacturer ? selectedManufacturer === data.name : true))
              .flatMap((disc) => disc.molds)}
          />
        </FormControl>
        <FormControl sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Medium>{t("plastic")}</Medium>
          <DiscAutocompleteInput
            methods={methods}
            name="plastic"
            data={discData
              .filter((data) => (selectedManufacturer ? selectedManufacturer === data.name : true))
              .flatMap((disc) => disc.plastics.map((p) => p.name))}
          />
        </FormControl>
      </Stack>

      <Stack gap={4} p={2}>
        <FormControl sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Strong color="primary.main">{t("nickname")}*</Strong>
          <TextField
            onFocus={(e) => e.target.select()}
            error={!!methods.formState.errors.nickname}
            helperText={methods.formState.errors.nickname?.message}
            inputProps={{ sx: { backgroundColor: "white" } }}
            sx={{ backgroundColor: "white", pr: 0 }}
            {...methods.register("nickname")}
          />
        </FormControl>
        <Stack justifyContent={"space-between"} sx={{ height: "100%" }}>
          <Stack
            sx={{
              display: "grid",
              columnGap: 3,
              rowGap: 1,
              gridTemplateColumns: { mobileMax: "repeat(2, minmax(0, 1fr))" },
              gridTemplateRows: "repeat(2, min-content)",
              justifyItems: "center",
              boxSizing: "border-box",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                p: 1,
              }}
            >
              <DiscRender
                autoSize
                shadowSize={5}
                discClass={selectedDiscClass}
                appearance={selectedAppearance}
                colors={selectedColors}
              />
            </Box>
            <Box
              sx={{
                display: colorSelection === "primary" ? "block" : "none",
                width: "100%",
              }}
            >
              <Controller
                control={methods.control}
                name="colors.primary"
                render={({ field }) => (
                  <HexColorPicker style={{ width: "100%" }} {...field} color={field.value} />
                )}
              />
            </Box>
            <Box
              sx={{
                display: colorSelection === "secondary" ? "block" : "none",
                width: "100%",
              }}
            >
              <Controller
                control={methods.control}
                name="colors.secondary"
                render={({ field }) => (
                  <HexColorPicker style={{ width: "100%" }} {...field} color={field.value} />
                )}
              />
            </Box>
            <Controller
              control={methods.control}
              name="appearance"
              render={({ field }) => (
                <TextField
                  select
                  error={!!methods.formState.errors.appearance}
                  helperText={methods.formState.errors.appearance?.message}
                  size="small"
                  {...field}
                  InputProps={{
                    fullWidth: true,
                    ...field,
                  }}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value={DiscAppearance.Solid}>{t("discAppearances.solid")}</MenuItem>
                  <MenuItem value={DiscAppearance.Overmold}>
                    {t("discAppearances.overmold")}
                  </MenuItem>
                  <MenuItem value={DiscAppearance.Burst}>{t("discAppearances.burst")}</MenuItem>
                </TextField>
              )}
            />
            <Stack
              direction="row"
              component={motion.div}
              alignItems="center"
              gap={1}
              sx={{ width: "100%" }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <ToggleButtonGroup
                exclusive
                value={colorSelection}
                sx={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}
                onChange={(_, selection) => (selection ? setColorSelection(selection) : null)}
              >
                <Box component={motion.div} sx={{ width: "100%" }} layout>
                  <ToggleButton value="primary" fullWidth>
                    <CircleIcon fontSize="small" sx={{ color: selectedColors?.primary }} />
                  </ToggleButton>
                </Box>
                {selectedAppearance !== DiscAppearance.Solid && (
                  <Box component={motion.div} sx={{ width: "100%" }} layout>
                    <ToggleButton value="secondary" fullWidth>
                      <RadioButtonUncheckedIcon fontSize="small" />
                    </ToggleButton>
                  </Box>
                )}
              </ToggleButtonGroup>
            </Stack>
          </Stack>
          <Box
            sx={{
              mt: 4,
              backgroundColor: "secondary.contrastText",
              border: "1px solid",
              borderColor: "primary.main",
              p: 1,
              textAlign: "center",
              borderRadius: 1,
              color: "primary.main",
            }}
          >
            These characteristics do not affect the flight of the simulated disc.
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default CustomizeDiscForm;

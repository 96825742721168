import { type TableData } from "@/components/tables/Table";
import type { StrokePlayLeaderboardEntry } from "@/model/leaderboard";
import { alpha, Box, Stack } from "@mui/material";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
  type ColumnDef,
} from "@tanstack/react-table";
import { AnimatePresence, motion } from "framer-motion";

export interface StrokePlayTableProps<TDataType extends TableData> {
  columns: ColumnDef<TDataType>[];
  data?: TDataType[];
}

const scoreContainer = {
  initial: { maxHeight: "0px", opacity: 0 },
  animate: { maxHeight: "250px", opacity: 1 },
  exit: { maxHeight: "0px", opacity: 0 },
  transition: { duration: 0.42 },
};
const containerVariants = {
  initial: false,
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.4, staggerChildren: 0.2 },
};

const rowVariants = {
  initial: { y: 10, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 10, opacity: 0 },
  transition: { duration: 0.4 },
};

export const StrokePlayTable = (props: StrokePlayTableProps<StrokePlayLeaderboardEntry>) => {
  const { columns, data = [] } = props;
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    columnResizeMode: "onChange",
    getRowCanExpand: () => true,
  });

  return (
    <Stack
      component={motion.div}
      variants={containerVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      sx={{
        maxWidth: "498px",
        width: "100%",
        gap: 1,
      }}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <Stack
          key={headerGroup.id}
          direction="row"
          justifyContent="space-between"
          sx={{
            width: "100%",
            px: { tablet: 4, mobile: 2 },
          }}
        >
          {headerGroup.headers.map((header) => (
            <Stack
              direction="row"
              key={header.id}
              sx={{
                width: header.column.getSize(),
                fontSize: { mobile: "0.9rem", tablet: "1.1rem" },
              }}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
            </Stack>
          ))}
        </Stack>
      ))}
      {data.length > 0 ? (
        <AnimatePresence mode="popLayout">
          {table.getRowModel().rows.map((row) => (
            <Stack
              component={motion.div}
              variants={rowVariants}
              key={row.id}
              sx={{
                border: "1px solid",
                width: "100%",
                borderColor: "grey.400",
                backgroundColor: "white",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  px: { tablet: 4, mobile: 2 },
                  py: { tablet: 1.5, mobile: 1 },
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <Stack
                    direction="row"
                    key={cell.id}
                    sx={{
                      flexGrow: 1,
                      width: cell.column.getSize(),
                      cursor: "pointer",
                    }}
                    onClick={row.getToggleExpandedHandler()}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Stack>
                ))}
              </Stack>
              {row.getIsExpanded() && (
                <Box
                  component={motion.div}
                  variants={scoreContainer}
                  gap={2}
                  sx={{
                    borderTop: "1px solid",
                    borderColor: "grey.400",
                    py: { mobile: 2 },
                    px: { mobile: 4 },
                    display: "flex",
                    flexDireciton: "row",
                  }}
                  key={`${row.original.userId}-expanded`}
                >
                  <Stack
                    sx={{
                      p: 2,
                      fontSize: "0.7rem",
                      fontWeight: 400,
                      color: "grey.600",
                      letterSpacing: -0.125,
                      placeSelf: "center",
                      borderRight: "1px solid",
                      borderColor: "grey.400",
                      textAlign: "right",
                      display: { mobile: "none", laptop: "block" },
                    }}
                  >
                    HOLES
                  </Stack>
                  <AnimatePresence>
                    {row.original.scorePerHole.map((score, scoreIndex) => (
                      <Stack
                        component={motion.div}
                        key={`${row.original.userId}-${scoreIndex}`}
                        alignItems="center"
                        gap={1}
                      >
                        <Box
                          sx={{
                            fontSize: "0.8rem",
                            fontWeight: 300,
                            color: "grey.600",
                          }}
                        >
                          {scoreIndex + 1}
                        </Box>

                        <Stack
                          sx={{
                            height: "32px",
                            width: "32px",
                            aspectRatio: 1,
                            borderRadius:
                              row.original.strokesPerHole[scoreIndex] === 1 || score < 0
                                ? "50%"
                                : 2,
                            transform: score < 0 ? "translateX(-5%)" : "translateX(0%)",
                            backgroundColor: (theme) =>
                              row.original.strokesPerHole[scoreIndex] === 1
                                ? "primary.main"
                                : score === 0
                                  ? "white"
                                  : score < 0
                                    ? alpha(theme.palette.primary.light, 0.5)
                                    : score < 6
                                      ? "grey.300"
                                      : "primary.light",
                            color:
                              row.original.strokesPerHole[scoreIndex] === 1
                                ? "white"
                                : score <= 0
                                  ? "primary.main"
                                  : score <= 3
                                    ? "grey.800"
                                    : "primary.dark",
                            fontSize:
                              row.original.strokesPerHole[scoreIndex] === 1 ? "0.7rem" : "1rem",
                            lineHeight: 1,
                            fontWeight: score === 0 ? 400 : score < 0 ? 500 : 400,
                            p: 1,
                            textAlign: "center",
                          }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {row.original.strokesPerHole[scoreIndex]}
                        </Stack>
                      </Stack>
                    ))}
                  </AnimatePresence>
                </Box>
              )}
            </Stack>
          ))}
        </AnimatePresence>
      ) : (
        <Stack direction="row" justifyContent="center" p={4}>
          <Box sx={{ color: "grey.400" }}>
            <em>*crickets*</em>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export const columnHelper = createColumnHelper<StrokePlayLeaderboardEntry>();

import { TabNavigation } from "@/components/navigation/TabNavigation/TabNavigation";
import { Container, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";

export default function PlayRoute() {
  return (
    <Container maxWidth="lg" sx={{ my: 4 }}>
      <Stack gap={4}>
        <TabNavigation />
        <Outlet />
      </Stack>
    </Container>
  );
}

import { FlightPathParams } from "@/dashboard/Simulate3D";
import type { UserMetadata } from "@/firebase/converters/leaderboardUserMetadata";
import { firebaseApp } from "@/firebaseConfig";
import { useDiscCollection } from "@/hooks/useDiscCollection";
import useTrackSubscription from "@/hooks/useTrackSubscription";
import { useUser } from "@/hooks/useUser";
import { useUserMetadata } from "@/hooks/useUserMetadata";
import { ThrowSummaryAndId } from "@/latestDashboard";
import type { DiscPreset } from "@/model/discs";
import { config, mobileConfig } from "@/simulator/utils";
import { useEntitlements } from "@/stripe";
import { getTrueUserId } from "@/summaryUtils";
import { TrackPoint } from "@/trackUtils";
import type { SimulatorCourseHole } from "@/types/simulator";
import {
  MultipleThrowEvent,
  SimulatedThrowEvent,
  SimulatorEvent,
  SimulatorEventType,
  SimulatorSettingsEvent,
  SimulatorSettingsEventType,
  SingleThrowEvent,
  UNITY_EVENT_VERSION,
} from "@/types/UnityEvents";
import { getRoleId } from "@/utils/roles";
import { Box } from "@mui/material";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Subject, distinctUntilChanged, tap } from "rxjs";
import { SimulatorMode } from "../Simulator";

declare global {
  interface Window {
    receiveFPSValues?: (
      sceneName: string,
      averageFPS: number,
      percentile99: number,
      percentile1: number,
    ) => void;
    unityOnReady?: () => void;
    unityMessage?: (type: string, data: object) => void;
  }
}

function detectDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /ipad|android.*tablet|tablet.*android/i.test(userAgent);
  const isMobile = /iphone|android.*mobile|mobile.*android/i.test(userAgent);

  if (isTablet) {
    return "tablet";
  } else if (isMobile) {
    return "phone";
  } else {
    return "desktop";
  }
}
// Create a subject to act as the message queue
const messageQueue$ = new Subject<SimulatorEvent>();
const settingsMessageQueue$ = new Subject<SimulatorSettingsEvent>();

// Device detection utility
const getDeviceConfig = (buildNumber?: string) => {
  const deviceType = detectDevice();
  if (deviceType === "phone" || deviceType === "tablet") {
    return mobileConfig(buildNumber);
  }
  return config(buildNumber);
};

interface UnityComponentProps {
  mode: SimulatorMode;
  userId: string;
  summary?: ThrowSummaryAndId;
  permissions: { allowedCourses: string[] | null; allowedLayouts: string[] | null };
  permissionsLoading: boolean;
  recentThrows?: ThrowSummaryAndId[];
  track?: TrackPoint[];
  flightPaths?: FlightPathParams[];
  buildNumber?: string;
  onInit?: () => void;
}

interface UnityPlayTokenRequest {
  playSessionId: string;
  userId: string;
}

async function handleMessage(
  sendMessage: (channel: string, method: string, data: string) => void,
  type: string,
  data: object,
) {
  if (type === "requestPlaySessionToken") {
    const { playSessionId, userId } = data as UnityPlayTokenRequest;
    if (!playSessionId || !userId) {
      console.error("Invalid play session ID or user ID");
      return;
    }
    const result = await httpsCallable(
      getFunctions(firebaseApp),
      "createPlaySessionToken",
    )({
      playSessionId: playSessionId,
    });

    const jwt = (result.data as { jwt: string })?.jwt;
    const payload = {
      userId,
      playSessionId,
      jwt,
    };
    const message = {
      version: UNITY_EVENT_VERSION,
      correlationId: playSessionId,
      event: SimulatorSettingsEventType.PlayTokenResponse,
      data: payload,
      timestamp: Date.now(),
    };
    sendMessage("JavascriptHookManager", "SettingsEvent", JSON.stringify(message));
  }
}

const sendInitMessage = async ({
  userId,
  userMetadata,
  discs,
  permissions,
}: {
  userId: string;
  userMetadata?: UserMetadata;
  discs: DiscPreset[] | null;
  permissions: {
    allowedCourses: { id?: string; displayName?: string; holes?: SimulatorCourseHole[] }[] | null;
    allowedLayouts: { id?: string; displayName?: string }[] | null;
  };
}) => {
  let result = { data: { jwt: "" } };

  try {
    result = (await httpsCallable(
      getFunctions(firebaseApp),
      "createPlaySessionToken",
    )({
      loadLastThrow: true,
    })) as { data: { jwt: string } };
  } catch (_err) {
    console.error("Error creating play session token:", _err);
  }

  const jwt = result.data.jwt;

  const message = {
    version: UNITY_EVENT_VERSION,
    correlationId: userId,
    event: SimulatorSettingsEventType.Init,
    data: {
      userId,
      userMetadata,
      discs,
      permissions,
      jwt,
    },
    timestamp: Date.now(),
  };
  settingsMessageQueue$.next(message);
};

export function UnityComponent(props: UnityComponentProps) {
  const {
    permissions,
    permissionsLoading,
    recentThrows,
    summary,
    flightPaths,
    userId,
    mode,
    buildNumber,
    onInit,
  } = props;
  const [{ user }] = useUser();
  const entitlements = useEntitlements(user);

  const [discs, collectionLoading] = useDiscCollection();
  const [isReady, setIsReady] = useState(false);

  const {
    unityProvider,
    sendMessage,
    unload,
    initialisationError,
    isLoaded,
    UNSAFE__unityInstance,
  } = useUnityContext(getDeviceConfig(buildNumber));
  const [initialized, setInitialized] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [userMetadata] = useUserMetadata(userId);
  const { backswing } = useTrackSubscription({
    userId,
    throwId: recentThrows?.[0]?.id,
    skip: !recentThrows?.[0]?.rotPerSec,
    type: "backswing",
  });

  useEffect(() => {
    if (isLoaded) {
      logEvent(getAnalytics(firebaseApp), "unity_loaded", { userId, client: "web" });
    } else {
      logEvent(getAnalytics(firebaseApp), "unity_request", { userId, client: "web" });
    }
  }, [isLoaded]);

  useEffect(() => {
    window.unityMessage = (type: string, data: object) => {
      if (type === "ready") {
        setIsReady(true);
        return;
      }
      console.log("Message from Unity of type " + type, data);
      handleMessage(sendMessage, type, data);
    };
    window.unityOnReady = () => {
      console.log(
        "%cReady",
        "color: white; background-color: green; padding: 2px 4px; border-radius: 4px; text-transform: uppercase",
      );
      setIsReady(true);
    };

    window.receiveFPSValues = (
      sceneName: string,
      averageFPS: number,
      percentile99: number,
      percentile1: number,
    ) => {
      // console.info("Average FPS:", averageFPS); // TODObrett muting this

      logEvent(getAnalytics(firebaseApp), "unity_perf", {
        sceneName: sceneName,
        averageFPS: averageFPS,
        percentile99: percentile99,
        percentile1: percentile1,
        client: "web",
        userId: getTrueUserId(),
      });
    };
  }, [userId, discs]);

  useEffect(() => {
    if (isReady && permissions && userId && !permissionsLoading && !collectionLoading) {
      sendInitMessage({ userId, userMetadata, permissions, discs });
    }
  }, [isReady, permissions, userMetadata, userId, discs, permissionsLoading, collectionLoading]);

  useEffect(() => {
    return () => {
      try {
        if (isLoaded) {
          window.unityOnReady = undefined;
          window.receiveFPSValues = undefined;
          if (canvasRef.current) {
            canvasRef.current.remove();
          }
        }
        const exec = async () => {
          if (isLoaded) {
            await UNSAFE__unityInstance?.Quit();
            console.debug("Unity unloaded");
          }
        };
        exec();
      } catch (err) {
        console.error("Error unloading Unity:", err);
      }
    };
  }, [unload, isLoaded]);

  useEffect(() => {
    return () => {
      if (initialisationError) {
        console.error("Error initializing Unity:", initialisationError);
      }
    };
  }, [initialisationError]);

  useEffect(() => {
    const eventSubscription = messageQueue$
      .pipe(
        distinctUntilChanged((previous, current) => {
          const prevId = previous.correlationId;
          const currentId = current.correlationId;
          const prevTrack = previous.data.backswing;
          const currentTrack = current.data.backswing;
          return (
            JSON.stringify(prevId) === JSON.stringify(currentId) ||
            JSON.stringify(prevTrack) === JSON.stringify(currentTrack)
          );
        }),
        tap((message) => {
          if (window.location.origin !== "https://techdisc.com") {
            console.groupCollapsed(
              "%cSIMULATE",
              "color: white; background-color: purple; padding: 2px 4px; border-radius: 4px; text-transform: uppercase",
            );
            console.debug(message);
            console.groupEnd();
          } else {
            console.log(
              "%cSIMULATE",
              "color: white; background-color: purple; padding: 2px 4px; border-radius: 4px; text-transform: uppercase",
            );
          }
          sendMessage("JavascriptHookManager", "SimulateEvent", JSON.stringify(message));
        }),
      )
      .subscribe({
        error: (err) => console.error("Error in combined message queue:", err),
      });

    return () => {
      eventSubscription.unsubscribe();
    };
  }, [sendMessage, mode]);

  useEffect(() => {
    const settingsSubscription = settingsMessageQueue$
      .pipe(
        distinctUntilChanged((previous, current) => {
          const prevId = previous.correlationId;
          const currentId = current.correlationId;
          return JSON.stringify(prevId) === JSON.stringify(currentId);
        }),
        tap((message) => {
          if (window.location.origin !== "https://techdisc.com") {
            console.groupCollapsed(
              `%c${message.event}`,
              "color: white; background-color: blue; padding: 2px 4px; border-radius: 4px; text-transform: uppercase",
            );
            console.debug(message);
            console.groupEnd();
          } else {
            console.log(
              `%c${message.event}`,
              "color: white; background-color: blue; padding: 2px 4px; border-radius: 4px; text-transform: uppercase",
            );
          }
          sendMessage("JavascriptHookManager", "SettingsEvent", JSON.stringify(message));
          setInitialized(true);
          onInit?.();
        }),
      )
      .subscribe({
        error: (err) => console.error("Error in combined message queue:", err),
      });

    return () => {
      settingsSubscription.unsubscribe();
    };
  }, [setInitialized, sendMessage]);

  useEffect(() => {
    // const adjust = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (initialized && summary) {
      if (mode === "single") {
        messageQueue$.next({
          version: UNITY_EVENT_VERSION,
          correlationId: summary.id,
          event: SimulatorEventType.SingleThrow,
          data: { summary: summary.originalSummary, backswing },
          timeZone,
          timestamp: Date.now(),
          roles: entitlements?.map((claim) => getRoleId(claim)),
        } as SingleThrowEvent);
      } else if (mode === "multiple") {
        messageQueue$.next({
          version: UNITY_EVENT_VERSION,
          correlationId: summary.id,
          event: SimulatorEventType.MultipleThrows,
          data: {
            summaries: recentThrows?.map((v) => v.originalSummary),
            // backswings: recentThrows?.map((v) => v.backswing),
          },
          timeZone,
          timestamp: Date.now(),
          roles: entitlements?.map((claim) => getRoleId(claim)),
        } as MultipleThrowEvent);
      } else {
        messageQueue$.next({
          version: UNITY_EVENT_VERSION,
          correlationId: summary.id,
          event: SimulatorEventType.SimulatedThrow,
          data: { flightPath: [] },
          timeZone,
          timestamp: Date.now(),
          roles: entitlements?.map((claim) => getRoleId(claim)),
        } as SimulatedThrowEvent);
      }
    }
  }, [backswing, initialized, recentThrows, flightPaths, summary, mode]);

  return (
    <Box
      id="unity-canvas"
      component={Unity}
      unityProvider={unityProvider}
      tabIndex={1}
      ref={canvasRef}
      matchWebGLToCanvasSize
      devicePixelRatio={window.devicePixelRatio}
      sx={{
        display: "block",
        outline: "0px",
        width: "100%",
        aspectRatio: 16 / 9,
        backgroundColor: "grey.50",
      }}
    />
  );
}

import { Button, FormControl, FormLabel, MenuItem, Select, Stack } from "@mui/material";
import { motion } from "framer-motion"; // Import framer motion
import { useCallback, useEffect } from "react";
import { StockShot } from "../../firebase/converters/stockShot";
import { FlightChart } from "../flight/XYFlightChart/FlightChart";
import { ThrowChip } from "../tables/ThrowChip";

export interface BaseStockShotFlightChartProps {
  stockShots: StockShot[];
  flightPath: THREE.Vector3[];
  selectedStockShot: StockShot;
}

export type StockShotFlightChartProps =
  | (BaseStockShotFlightChartProps & {
      readOnly?: false | undefined;
      onStockShotChange: (stockShot: StockShot) => void;
    })
  | (BaseStockShotFlightChartProps & { readOnly?: true; onStockShotChange?: never });

export default function StockShotFlightChart(props: StockShotFlightChartProps) {
  const { stockShots, onStockShotChange, flightPath, readOnly, selectedStockShot } = props;
  const isInteractive = !readOnly && onStockShotChange;

  const handleSimulateFlightNumbers = useCallback(async () => {
    if (isInteractive) {
      onStockShotChange(selectedStockShot);
    }
  }, [onStockShotChange, readOnly, selectedStockShot]);

  useEffect(() => {
    if (isInteractive) {
      onStockShotChange(selectedStockShot);
    }
  }, []);
  return (
    <Stack gap={2}>
      {readOnly ? null : (
        <Stack
          alignItems="flex-end"
          sx={{ gap: 2, flexDirection: { mobile: "column", mobileWide: "row" } }}
        >
          <FormControl fullWidth>
            <FormLabel htmlFor="stock-shot-selection">Stock Shot</FormLabel>
            <Select
              id="stock-shot-selection"
              size="small"
              value={selectedStockShot.id}
              onChange={(e) => {
                const stockShot = stockShots.find((ss) => ss.id === e.target.value);
                if (stockShot && isInteractive) {
                  onStockShotChange(stockShot);
                }
              }}
            >
              {stockShots?.map((stockShot) =>
                stockShot ? (
                  <MenuItem value={stockShot.id} key={stockShot.id}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <ThrowChip summary={stockShot} /> {stockShot.shotType}
                    </Stack>
                  </MenuItem>
                ) : null,
              )}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={handleSimulateFlightNumbers}
            sx={{ width: { mobile: "100%", mobileWide: "auto" } }}
          >
            Simulate
          </Button>
        </Stack>
      )}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <FlightChart throwSummary={selectedStockShot} flightPath={flightPath} />
      </motion.div>
    </Stack>
  );
}

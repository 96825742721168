import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import MetricDisplay from "../../dashboard/MetricDisplay";
import { ThrowSummary } from "../../model/throwSummary";
import { formatNumber } from "../../utils/measurement";
import { useGlobal } from "../providers/GlobalProvider";

export interface CoreMetricsProps {
  summary?: ThrowSummary;
  metricsLoading?: boolean;
}

function CoreMetricsComponent(props: CoreMetricsProps) {
  const { summary, metricsLoading } = props;
  const { prefersMetric } = useGlobal();
  const [t] = useTranslation();

  return (
    <Stack
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: {
          md: "repeat(3, 1fr)",
          sm: "repeat(3, 1fr)",
          mobile: "repeat(2, 1fr)",
        },
        gap: { mobile: 1, md: 2 },
      }}
    >
      <MetricDisplay
        loading={metricsLoading}
        stats={summary}
        accessor={(cs) =>
          ((cs?.speedKmh || cs?.speedMph) &&
            formatNumber(prefersMetric ? cs?.speedKmh : cs?.speedMph, {
              maximumFractionDigits: 1,
            })) ??
          "-"
        }
        unit={prefersMetric ? t("units.kmh.upper") : t("units.mph.upper")}
        label={t("metrics.speed.full", "Speed")}
      />
      <MetricDisplay
        loading={metricsLoading}
        stats={summary}
        accessor={(cs) => cs.spinRpm?.toFixed(0) ?? "-"}
        unit={t("units.rpm.upper")}
        label={t("metrics.spin.full", "Spin")}
      />
      <MetricDisplay
        loading={metricsLoading}
        stats={summary}
        accessor={(cs) => cs.wobble?.toFixed(1) ?? "-"}
        unit={t("units.degrees.upper")}
        label={t("metrics.wobble.full", "Wobble")}
      />
      <MetricDisplay
        loading={metricsLoading}
        stats={summary}
        accessor={(cs) => cs.hyzerAngle?.toFixed(1) ?? "-"}
        unit={t("units.degrees.upper")}
        label={t("metrics.hyzer.full", "Hyzer")}
      />
      <MetricDisplay
        loading={metricsLoading}
        stats={summary}
        accessor={(cs) => cs.noseAngle?.toFixed(1) ?? "-"}
        unit={t("units.degrees.upper")}
        label={t("metrics.nose.full", "Nose")}
      />
      <MetricDisplay
        loading={metricsLoading}
        stats={summary}
        accessor={(cs) => cs.launchAngle?.toFixed(1) ?? "-"}
        unit={t("units.degrees.upper")}
        label={t("metrics.launch.full", "Launch")}
      />
    </Stack>
  );
}

export default CoreMetricsComponent;

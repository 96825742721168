import { useGlobal } from "@/components/providers/GlobalProvider";
import { Box } from "@mui/material";
import { useMatches, useNavigate } from "react-router-dom";
import { NavigationItem } from "./NavigationItem";

export function TabNavigation() {
  const navigate = useNavigate();

  const matches = useMatches();
  const { featureFlags } = useGlobal();
  return (
    <Box
      sx={{
        display: "flex",
        p: 0.5,
        mb: 4,
        gap: 2,
        mx: "auto",
        width: "fit-content",
      }}
    >
      {featureFlags?.stroke_play && (
        <NavigationItem
          label="PLAY"
          isSelected={matches.some((match) => match.pathname.includes("/play/stroke"))}
          onClick={() => navigate("/play/stroke")}
        />
      )}
      <NavigationItem
        label="LEADERBOARDS"
        isSelected={matches.some((match) => match.pathname.includes("/play/leaderboards"))}
        onClick={() => navigate("/play/leaderboards")}
      />
    </Box>
  );
}

import { FirstPlaceIcon } from "@/components/icons/FirstPlaceIcon";
import { SecondPlaceIcon } from "@/components/icons/SecondPlaceIcon";
import { ThirdPlaceIcon } from "@/components/icons/ThirdPlaceIcon";
import { getDisplayNamePlaceholder } from "@/components/leaderboard/fakeNames";
import type { LeaderboardUserMetadata, StrokePlayLeaderboardEntry } from "@/model/leaderboard";
import { getLeaderboardUserMetadata } from "@/summaryUtils";
import { alpha, Avatar, Box, Stack } from "@mui/material";
import { intervalToDuration } from "date-fns/esm";
import { getDoc } from "firebase/firestore";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { getGolfScore } from "./utils";

export const StrokePlayScorecard = ({ entry }: { entry: StrokePlayLeaderboardEntry }) => {
  const [userMetadata, setUserMetadata] = useState<LeaderboardUserMetadata>();
  const [userMetadataLoading, setUserMetadataLoading] = useState(false);
  const computedScore = entry.scorePerHole.reduce((acc, score) => acc + score, 0);
  const entryTimestamp = useMemo(() => {
    if (!entry.finishTime) return null;
    const duration = intervalToDuration({ start: new Date(), end: new Date(entry?.finishTime) });
    if (!duration) return null;
    if (duration.days) {
      return `${duration.days}d ${duration.hours}h ${duration.minutes}m ago`;
    } else if (duration.hours) {
      return `${duration.hours}h ${duration.minutes}m ago`;
    } else if (duration.minutes) {
      return `${duration.minutes}m ago`;
    }
    return `${duration.seconds}s ago`;
  }, [entry]);

  useEffect(() => {
    const fetchUserMetadata = async () => {
      setUserMetadataLoading(true);
      const metadataDoc = await getDoc(getLeaderboardUserMetadata(entry.userId));
      const metadata = metadataDoc.data();
      if (metadata) {
        setUserMetadata(metadata);
      }
      setUserMetadataLoading(false);
    };
    fetchUserMetadata();
  }, [entry, setUserMetadata, setUserMetadataLoading]);

  return (
    <Stack
      component={motion.div}
      sx={{
        backgroundColor: "white",
        border: "1px solid",
        borderColor: "grey.400",
        p: 1.5,
        width: "100%",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        gap: { mobile: 1, tablet: 2 },
        position: "relative",
        userSelect: "none",
      }}
      direction={{ mobile: "column", tablet: "row" }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack
        direction={{ mobile: "column", tablet: "row" }}
        gap={{ mobile: 1, tablet: 2 }}
        sx={{ height: "max-content", p: 1.5 }}
        alignItems="center"
      >
        <Box
          sx={{
            position: "absolute",
            top: { desktop: "25%", mobile: -32 },
            transform: { mobile: "translateX(-50%)", desktop: "translateY(-25%)" },
            left: { desktop: -48, mobile: "50%" },
          }}
        >
          {entry.rank === 1 ? (
            <FirstPlaceIcon width={64} />
          ) : entry.rank === 2 ? (
            <SecondPlaceIcon width={64} />
          ) : entry.rank === 3 ? (
            <ThirdPlaceIcon width={64} />
          ) : null}
        </Box>

        <Stack direction="row" alignItems="center" gap={2}>
          <Avatar
            src={userMetadata?.photo}
            alt={userMetadata?.displayName}
            sx={{
              width: 64,
              height: 64,
              border: entry.rank <= 3 ? "2px solid" : "1px solid",
              borderColor: entry.rank <= 3 ? "primary.main" : "primary.light",
            }}
          />

          <Stack gap={0.5}>
            <Box sx={{ fontSize: "1.5rem", fontWeight: 300, minHeight: "1.5rem" }}>
              <AnimatePresence mode="wait">
                {userMetadata?.name ? (
                  <Box
                    component={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    {userMetadata?.name}
                  </Box>
                ) : entry?.displayName ? (
                  entry?.displayName
                ) : (
                  <Box sx={{ fontSize: "1.5rem", fontWeight: 300, minHeight: "1.5rem" }}>
                    {getDisplayNamePlaceholder(entry?.userId)}
                  </Box>
                )}
              </AnimatePresence>
            </Box>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Box sx={{ fontSize: "0.8rem", fontWeight: 300, color: "grey.600" }}>
                {entryTimestamp}
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { mobile: "1fr", tablet: "1fr 1fr" },
          borderLeft: { tablet: "1px solid", laptop: "none" },
          borderColor: { tablet: "grey.400", laptop: "transparent" },
        }}
      >
        <Stack
          sx={{
            p: 2,
            fontSize: "0.9rem",
            fontWeight: 500,
            color: "grey.600",
            letterSpacing: -0.125,
            placeSelf: "center",
            borderRight: "1px solid",
            borderColor: "grey.400",
            height: "100%",
            width: "100%",
            textAlign: "right",
            display: { mobile: "none", laptop: "block" },
          }}
        >
          OVERALL
        </Stack>
        <Stack
          gap={1}
          sx={{
            px: 4,
            fontSize: "2.2rem",
            fontWeight: computedScore <= 0 ? 500 : 300,
            height: "100%",
            placeSelf: "center",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {getGolfScore(computedScore)}
        </Stack>
        <Stack
          sx={{
            p: 2,
            fontSize: "0.7rem",
            fontWeight: 400,
            color: "grey.600",
            letterSpacing: -0.125,
            placeSelf: "center",
            borderRight: "1px solid",
            borderColor: "grey.400",
            height: "100%",
            width: "100%",
            textAlign: "right",
            display: { mobile: "none", laptop: "block" },
          }}
        >
          HOLES
        </Stack>
        <Stack
          direction="row"
          sx={{
            p: 2,
            gap: 1,
            borderTop: { mobile: "1px solid", tablet: "none", laptop: "1px solid" },
            borderColor: { mobile: "grey.400", tablet: "transparent", laptop: "grey.400" },
            height: "100%",
            width: "100%",
          }}
        >
          {entry.scorePerHole.map((score, scoreIndex) => (
            <Stack key={`${entry.userId}-${scoreIndex}`} alignItems="center" gap={1}>
              <Box
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 300,
                  color: "grey.600",
                }}
              >
                {scoreIndex + 1}
              </Box>

              <Stack
                sx={{
                  height: "36px",
                  width: "36px",
                  aspectRatio: 1,
                  borderRadius: entry.strokesPerHole[scoreIndex] === 1 || score < 0 ? "50%" : 2,
                  transform: score < 0 ? "translateX(-5%)" : "translateX(0%)",
                  backgroundColor: (theme) =>
                    entry.strokesPerHole[scoreIndex] === 1
                      ? "primary.main"
                      : score === 0
                        ? "white"
                        : score < 0
                          ? alpha(theme.palette.primary.light, 0.5)
                          : score < 6
                            ? "grey.300"
                            : "primary.light",
                  color:
                    entry.strokesPerHole[scoreIndex] === 1
                      ? "white"
                      : score <= 0
                        ? "primary.main"
                        : score <= 3
                          ? "grey.800"
                          : "primary.dark",
                  fontSize: entry.strokesPerHole[scoreIndex] === 1 ? "0.7rem" : "1rem",
                  lineHeight: 1,
                  fontWeight: score === 0 ? 400 : score < 0 ? 500 : 400,
                  p: 1,
                  textAlign: "center",
                }}
                alignItems="center"
                justifyContent="center"
              >
                {entry.strokesPerHole[scoreIndex]}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};

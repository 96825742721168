import { useUser } from "@/hooks/useUser";
import { useEntitlements } from "@/stripe";

import type { SimulatorCourseHole } from "@/types/simulator";
import { getUserPermissions } from "@/utils/auth";
import { useEffect, useState } from "react";

export const useSimulatorPermissions = () => {
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [allowedCourses, setAllowedCourses] = useState<
    { id?: string; displayName?: string; holes?: SimulatorCourseHole[] }[] | null
  >(null);
  const [layoutsLoading, setLayoutsLoading] = useState(true);
  const [allowedLayouts, setAllowedLayouts] = useState<
    { id?: string; displayName?: string }[] | null
  >(null);
  const [{ user, trueUserId }] = useUser();

  const entitlements = useEntitlements(user);

  useEffect(() => {
    async function getPermissions() {
      const permissions = await getUserPermissions(trueUserId, entitlements);
      setAllowedCourses(permissions?.allowedCourses ?? []);
      setAllowedLayouts(permissions?.allowedLayouts ?? []);
      setCoursesLoading(false);
      setLayoutsLoading(false);
    }
    getPermissions();
  }, [trueUserId, entitlements]);

  return [
    { allowedCourses, allowedLayouts },
    { coursesLoading, layoutsLoading },
  ] as const;
};

import { AuthClaim } from "@/components/admin/PermissionsManager";
import { PremiumFeature } from "@/stripe";

export const getRoleId = (claim: AuthClaim | PremiumFeature) => {
  // ids from Firebase /admin/users/roles
  // https://td-web.web.app/admin/security/roles
  switch (claim) {
    case AuthClaim.admin:
      return "TftwE20kPMPmiVHh4CGp";
    case AuthClaim.beta:
      return "JFjfCQxgLKiPo4XRojuH";
    case AuthClaim.dev:
      return "BiW3zpSwYlnltmmEMihH";
    case AuthClaim.store:
      return "wt4Rbz3maTORJTiLYjyH";
    case AuthClaim.debug:
      return "O4SvDiJp3BIq0XpIvV50";
    case AuthClaim.debugThrows:
      return "LvjKBti79q8C0vzq6z37";
    case AuthClaim.devices:
      return "X5tyllhOEQgCsdiRPM5V";
    case AuthClaim.earlyAccess:
      return "PN3Tkn7i9hROO9tVPWw2";
    case AuthClaim.manager:
      return "NA8zQnDA6hv1REniQ34T";
    case PremiumFeature.PRO:
      return "c5qUkS59eGOq8LJxdw0N";
    case PremiumFeature.PLAY:
      return "zKBzw6LO2AQe9dL3OMcL";
    case PremiumFeature.STORE:
      return "nAV99wL0kYuWYZA6q2pS";
    default:
      return null;
  }
};

import { Timestamp } from "firebase/firestore";
import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import { useRecentThrows } from "../../hooks/useLatestThrow";
import { ThrowSummary } from "../../model/throwSummary";

export enum ThrowSessionStatus {
  Idle = "idle",
  InProgress = "in_progress",
  Paused = "paused",
  Fulfilled = "fulfilled",
  Completed = "completed",
}

export type ThrowSessionState = {
  throws: ThrowSummary[];
  minThrows?: number;
  maxThrows?: number;
  startTime?: Timestamp;
  latestThrow?: ThrowSummary;
  latestThrowLoading?: boolean;
  status: ThrowSessionStatus;
  addThrow: (summary?: ThrowSummary) => void;
  endSession: () => void;
  restartSession: () => void;
  pauseSession: () => void;
  startSession: () => void;
};

export type Action =
  | { type: "ADD_THROW"; payload: ThrowSummary }
  | { type: "COMPLETE" }
  | { type: "PAUSE" }
  | { type: "START" }
  | { type: "RESTART" };

export function throwSessionReducer(state: ThrowSessionState, action: Action): ThrowSessionState {
  switch (action.type) {
    case "ADD_THROW": {
      if (state.status === ThrowSessionStatus.Completed) {
        return state;
      }
      const newThrows = [action.payload, ...state.throws];
      const maxThrows = state.maxThrows ?? Infinity;
      const minThrows = state.minThrows ?? Infinity;
      // Maximum Throws
      if (newThrows.length < minThrows) {
        // Minimum Throws
        // Minimum not reached
        return { ...state, throws: newThrows, status: ThrowSessionStatus.InProgress };
      } else if (newThrows.length === maxThrows) {
        // Incoming throw will complete the session
        return { ...state, throws: newThrows, status: ThrowSessionStatus.Fulfilled };
      } else if (newThrows.length >= minThrows) {
        // Incoming throw will complete the session
        return { ...state, throws: newThrows, status: ThrowSessionStatus.Fulfilled };
      } else if (newThrows.length < maxThrows) {
        // Maximum not reached
        return { ...state, throws: newThrows, status: ThrowSessionStatus.InProgress };
      }

      return state;
    }
    case "COMPLETE":
      return { ...state, status: ThrowSessionStatus.Completed };
    case "RESTART":
      return { ...state, throws: [], status: ThrowSessionStatus.Idle };
    case "PAUSE":
      return { ...state, status: ThrowSessionStatus.Paused };
    case "START":
      // TODO: Update to start with the correct status, either InProgress or Fulfilled
      return { ...state, status: ThrowSessionStatus.InProgress };
    default:
      return state;
  }
}

const defaultState: ThrowSessionState = {
  throws: [],
  startTime: Timestamp.now(),
  status: ThrowSessionStatus.Idle,
  addThrow: () => undefined,
  endSession: () => undefined,
  restartSession: () => undefined,
  pauseSession: () => undefined,
  startSession: () => undefined,
};

const ThrowSessionContext = createContext<ThrowSessionState>(defaultState);

export const ThrowSessionProvider = ({
  userId,
  children,
  initialState = {},
}: {
  userId: string;
  children: React.ReactNode;
  initialState: Partial<ThrowSessionState>;
}) => {
  const [state, dispatch] = useReducer(
    throwSessionReducer,
    { ...initialState, ...defaultState } ?? defaultState,
  );

  const { latestThrow, loading, reset } = useRecentThrows({
    userId,
    maxThrows: state.maxThrows,
    startTime: state.startTime,
    skip: state.throws.length >= (state.maxThrows ?? Infinity),
  });

  const addThrow = useCallback(
    (summary?: ThrowSummary) => {
      if (!summary) {
        return;
      }
      dispatch({ type: "ADD_THROW", payload: summary });
    },
    [dispatch],
  );

  const restartSession = useCallback(() => {
    dispatch({ type: "RESTART" });
    reset();
  }, [dispatch, reset]);

  const pauseSession = useCallback(() => {
    dispatch({ type: "PAUSE" });
  }, [dispatch]);

  const startSession = useCallback(() => {
    dispatch({ type: "START" });
  }, [dispatch]);

  const endSession = useCallback(() => {
    dispatch({ type: "COMPLETE" });
  }, [dispatch]);

  useEffect(() => {
    if (latestThrow && latestThrow.rotPerSec) {
      addThrow(latestThrow);
    }
  }, [addThrow, latestThrow]);

  return (
    <ThrowSessionContext.Provider
      value={{
        ...state,
        addThrow,
        endSession,
        restartSession,
        pauseSession,
        startSession,
        latestThrow,
        latestThrowLoading: loading,
      }}
    >
      {children}
    </ThrowSessionContext.Provider>
  );
};

export const useThrowSession = () => useContext(ThrowSessionContext);

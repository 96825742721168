import * as yup from "yup";

export type UserProfile = yup.InferType<typeof userProfileSchema>;

export const userProfileSchema = yup.object({
  name: yup.string().optional(),
  pdgaNumber: yup.string().optional(),
  photo: yup.string().optional(),
  preferredMeasurementSystem: yup.string().optional(),
});

import { getUserAdminDoc } from "@/firebase/docs";
import { getDoc, getDocs } from "firebase/firestore";

import {
  getAdminSimulatorCourseCollection,
  getAdminSimulatorLayoutCollection,
  getAdminSimulatorPermissions,
  getAdminUserRolesCollection,
} from "@/firebase/collections";
import type { Entitlement } from "@/stripe";

export const getUserPermissions = async (userId?: string, entitlements?: Entitlement[]) => {
  try {
    const userRolesDocs = await getDocs(getAdminUserRolesCollection());
    const userRoles = userRolesDocs.docs.map((doc) => doc.data());
    const externalRoles = userRoles.filter((role) => role.external);
    const courseIds: Set<string> = new Set();
    const layoutIds: Set<string> = new Set();
    const simulatorPermissionIds: Set<string> = new Set(userId ? ["public", "free"] : ["public"]);

    // If the user is logged in, we need to check their roles for additional permissions
    if (userId) {
      const userAdminDoc = await getDoc(getUserAdminDoc(userId));

      if (userAdminDoc.exists()) {
        const { roles: assignedRoles = [], permissions } = userAdminDoc.data();
        const assignedExternalRoles = externalRoles.filter((role) =>
          entitlements?.includes(role.claimToken),
        );

        const roles = [
          ...assignedRoles.map((roleId) => userRoles.find((role) => role.id === roleId)),
          ...assignedExternalRoles,
        ];
        for (const role of roles) {
          const roleBasedPermissions = role?.permissions?.simulator ?? [];
          for (const permissionId of roleBasedPermissions) {
            simulatorPermissionIds.add(permissionId);
          }
        }

        const userPermissions = permissions?.simulator ?? [];

        for (const permissionId of userPermissions) {
          simulatorPermissionIds.add(permissionId);
        }
      }
    }

    const simulatorCoursesDocs = await getDocs(getAdminSimulatorCourseCollection());
    const simulatorPermissionsDocs = await getDocs(getAdminSimulatorPermissions());
    const simulatorLayoutsDocs = await getDocs(getAdminSimulatorLayoutCollection());

    const simulatorCourses = simulatorCoursesDocs.docs.map((doc) => doc.data());
    const simulatorPermissions = simulatorPermissionsDocs.docs.map((doc) => doc.data());
    const simulatorLayouts = simulatorLayoutsDocs.docs.map((doc) => doc.data());

    const permissions = Array.from(simulatorPermissionIds).map((id) =>
      simulatorPermissions.find((permission) => permission.id === id),
    );
    // Get the courses that the user is allowed to use, always including the public permissions
    for (const permission of permissions) {
      if (permission) {
        for (const courseId of permission.allowedCourses) {
          courseIds.add(courseId);
        }
        if (permission.allowedLayouts) {
          for (const layoutId of permission.allowedLayouts) {
            layoutIds.add(layoutId);
          }
        }
      }
    }

    // Format the course data to include the display name
    const courseData = Array.from(courseIds).map((courseId) => {
      const course = simulatorCourses.find((course) => course.id === courseId);
      return {
        id: courseId,
        displayName: course?.name,
        holes: course?.holes,
        assetGuid: course?.assetGuid,
      };
    });

    const layoutData = Array.from(layoutIds).map((layoutId) => {
      const layout = simulatorLayouts.find((layout) => layout.id === layoutId);

      const courses = layout?.holes.map(({ courseId }) => {
        const course = simulatorCourses.find((course) => course.id === courseId);
        return {
          id: courseId,
          displayName: course?.name,
          assetGuid: course?.assetGuid,
        };
      });
      const holes = layout?.holes.map(({ holeId, courseId }) => {
        const course = simulatorCourses.find((course) => course.id === courseId);
        return {
          id: holeId,
          courseId,
          number: course?.holes.find((hole) => hole.id === holeId)?.number,
          nickname: course?.holes.find((hole) => hole.id === holeId)?.nickname,
        };
      });

      return {
        id: layoutId,
        displayName: layout?.name,
        holes,
        courses,
      };
    });

    return {
      allowedCourses: courseData,
      allowedLayouts: layoutData,
    };
  } catch (error) {
    console.error(error);
  }
};

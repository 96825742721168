import type { LiveEvent } from "@/firebase/converters/events";
import { Box } from "@mui/material";
import { ReactNode, useCallback } from "react";
import { Link, useRouteLoaderData } from "react-router-dom";
import useLive from "../../hooks/useLive";
import { useUser } from "../../hooks/useUser";
import { ThrowSummary } from "../../model/throwSummary";
import { useGlobal } from "../providers/GlobalProvider";
import { Medium } from "../Text";
import { ThrowChip } from "./ThrowChip";

type ThrowTypeCellProps = {
  children: ReactNode;
  summary: ThrowSummary;
};
export const ThrowTypeCell = (props: ThrowTypeCellProps) => {
  const { summary, children } = props;
  const { isShareRoute, isInspectRoute } = useGlobal();
  const [{ userId, trueUserId }] = useUser();
  const { isLiveRoute } = useLive();
  const { activeEvent } = useRouteLoaderData("root") as { activeEvent: LiveEvent };

  const getThrowLink = useCallback(
    (throwSummary: ThrowSummary) => {
      if (isInspectRoute) {
        return `/admin/inspect/throws/${throwSummary.id}`;
      }
      if (isShareRoute) {
        return `/s/throws/${userId}/${throwSummary.id}`;
      }
      if (isLiveRoute || (Boolean(activeEvent) && !trueUserId)) {
        return `/live/${activeEvent?.id}/throws/${throwSummary.id}`;
      }
      return `/throws/${throwSummary.id}`;
    },
    [isInspectRoute, isShareRoute, isLiveRoute, userId, trueUserId, activeEvent],
  );

  return (
    <>
      <ThrowChip summary={summary} />
      <Medium spacing="dense">
        <Box
          component={Link}
          sx={{
            fontWeight: 400,
            color: "primary.main",
            textDecoration: "none",
            "&:visited": {
              textDecoration: "none",
            },
            "&:hover": { color: "primary.dark", textDecoration: "none" },
          }}
          // Prevent the event from triggering row expansion
          onClick={(e) => e.stopPropagation()}
          to={getThrowLink(summary)}
        >
          {children}
        </Box>
      </Medium>
    </>
  );
};

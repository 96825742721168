import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={53} fill="none" {...props}>
    <path
      fill="#fff"
      d="M24.143 20.918c0-6.346 4.716-7.955 7.857-7.955s7.857 1.609 7.857 7.955S34.597 40.037 32 40.037c-2.597 0-7.857-12.773-7.857-19.12Z"
    />
    <path
      fill="#2A81DE"
      d="M32 12.463c-1.643 0-3.725.419-5.41 1.7-1.707 1.299-2.947 3.441-2.947 6.755h1c0-3.032 1.118-4.867 2.553-5.96 1.46-1.109 3.305-1.495 4.804-1.495v-1Zm8.357 8.455c0-3.314-1.24-5.456-2.948-6.755-1.684-1.281-3.767-1.7-5.409-1.7v1c1.499 0 3.345.386 4.804 1.496 1.435 1.092 2.553 2.927 2.553 5.959h1ZM32 40.537c.518 0 1.004-.309 1.422-.694.434-.4.878-.96 1.318-1.625.883-1.334 1.798-3.16 2.625-5.17 1.648-4.008 2.992-8.86 2.992-12.13h-1c0 3.076-1.286 7.783-2.917 11.749-.813 1.976-1.7 3.739-2.534 4.999-.418.631-.81 1.118-1.162 1.442-.366.338-.613.429-.744.429v1Zm-8.357-19.62c0 3.271 1.344 8.123 2.992 12.13.827 2.01 1.742 3.837 2.625 5.17.44.666.884 1.226 1.318 1.626.418.385.904.694 1.422.694v-1c-.131 0-.378-.091-.744-.43-.351-.323-.744-.81-1.162-1.441-.834-1.26-1.721-3.023-2.534-5-1.631-3.965-2.917-8.672-2.917-11.748h-1Z"
    />
    <ellipse cx={32} cy={20.918} fill="#2A81DE" rx={3.143} ry={3.182} />
    <path
      fill="#fff"
      d="M6.143 20.918c0-6.346 4.716-7.955 7.857-7.955s7.857 1.609 7.857 7.955S16.597 40.037 14 40.037c-2.597 0-7.857-12.773-7.857-19.12Z"
    />
    <path
      fill="#2A81DE"
      d="M14 12.463c-1.643 0-3.725.419-5.41 1.7-1.707 1.299-2.947 3.441-2.947 6.755h1c0-3.032 1.118-4.867 2.553-5.96 1.46-1.109 3.305-1.495 4.804-1.495v-1Zm8.357 8.455c0-3.314-1.24-5.456-2.948-6.755-1.684-1.281-3.766-1.7-5.409-1.7v1c1.499 0 3.345.386 4.804 1.496 1.435 1.092 2.553 2.927 2.553 5.959h1ZM14 40.537c.518 0 1.004-.309 1.422-.694.434-.4.878-.96 1.318-1.625.883-1.334 1.798-3.16 2.625-5.17 1.648-4.008 2.992-8.86 2.992-12.13h-1c0 3.076-1.286 7.783-2.917 11.749-.813 1.976-1.7 3.739-2.534 4.999-.418.631-.81 1.118-1.162 1.442-.366.338-.613.429-.744.429v1Zm-8.357-19.62c0 3.271 1.344 8.123 2.992 12.13.827 2.01 1.742 3.837 2.625 5.17.44.666.884 1.226 1.318 1.626.418.385.904.694 1.422.694v-1c-.131 0-.378-.091-.744-.43-.351-.323-.744-.81-1.162-1.441-.834-1.26-1.721-3.023-2.534-5-1.631-3.965-2.917-8.672-2.917-11.748h-1Z"
    />
    <ellipse cx={14} cy={20.918} fill="#2A81DE" rx={3.143} ry={3.182} />
    <g clipPath="url(#a)">
      <g filter="url(#b)">
        <path
          fill="#2A81DE"
          d="M12.73 18.982c0-7.977 6.001-10 10-10 3.997 0 10 2.023 10 10 0 7.978-6.696 24.035-10 24.035-3.306 0-10-16.057-10-24.035Z"
        />
        <path
          stroke="#2A81DE"
          d="M12.73 18.982c0-7.977 6.001-10 10-10 3.997 0 10 2.023 10 10 0 7.978-6.696 24.035-10 24.035-3.306 0-10-16.057-10-24.035Z"
        />
      </g>
      <path
        fill="#2A81DE"
        stroke="#BBDBF9"
        d="M12.73 18.982c0-7.977 6.001-10 10-10 3.997 0 10 2.023 10 10 0 7.978-6.696 24.035-10 24.035-3.306 0-10-16.057-10-24.035Z"
      />
      <path
        stroke="#fff"
        strokeWidth={2.5}
        d="M22.686 15.1v5.026m0 0V25.1m0-4.974h5.043m-5.043 0h-4.957"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M8.73 4h28v44h-28z" />
      </clipPath>
      <filter
        id="b"
        width={29}
        height={43.035}
        x={8.229}
        y={4.482}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_215_2089" stdDeviation={2} />
      </filter>
    </defs>
  </svg>
);
export { SvgComponent as PremiumStorePinCluster };

import { ThrowSessionProvider } from "@/components/context/ThrowSessionProvider";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { StrokePlay } from "@/components/StrokePlay";
import { Container } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { useUser } from "../../hooks/useUser";

function StrokePlayRoute() {
  const [{ userId }] = useUser();

  return (
    <ThrowSessionProvider userId={userId} initialState={{ startTime: Timestamp.now() }}>
      <ErrorBoundary eventName="simulator_play_error">
        <Container maxWidth="xl">
          <StrokePlay />
        </Container>
      </ErrorBoundary>
    </ThrowSessionProvider>
  );
}

export default StrokePlayRoute;
